@import '../../abstracts/variables.sass';

.sidebarTheme1 {
  padding: 2rem;
  border: $input-border-small;
  border-color: #ada5a578;
  box-shadow: $normal-box-shadow;
  background-color: var(--sidebar-background-color);
  border-radius: $normal-border-radius;
  transition: $normal-transition;
  min-width: 30rem;
  height: 100%;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 750px) {
    height: calc(100vh - 7.5rem);
    overflow-y: scroll;
    -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
    &--left {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      overflow: hidden;
      border: $input-border-small;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &--right {
      margin-left: 1rem;
      h2 {
        font-size: 1.8rem;
        font-family: var(--main-font-primary);
        color: var(--main-text-color);
        font-weight: 500 !important;
        margin-bottom: 0.2rem;
        line-height: 2rem;
      }

      p {
        color: $main-light-gray-font-color-3;
        font-size: 1.2rem;
        font-family: var(--main-font-secondary) !important;
        margin-bottom: 0rem;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0;

    &--linkItem {
      &-goBack {
        // justify-content: space-evenly;

        svg {
          transform: rotate(180deg);
        }
      }

      width: 100%;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: $white-border;
      background-color: var(--sidebar-background-color);
      border-radius: $normal-border-radius;
      transition: $normal-transition;
      padding: 0rem 2rem;
      margin: 0.5rem 0rem;
      cursor: pointer;

      &[data-sub_page_link_holder='true'] {
        border: $input-border-small;
        border-color: var(--main-brand-color);
        background-color: var(--main-brand-color);
        /* border-color: var(--subPageLinkHolder-link-hover-color);
        background-color: var(--subPageLinkHolder-link-selected-color); */
        box-shadow: $normal-box-shadow;

        span {
          // color: var(--main-brand-color);
          color: var(--main-btn-text-color);
        }
      }
      &:hover[data-sub_page_link_holder='true'] {
        // background-color: var(--subPageLinkHolder-link-hover-color);
        background-color: var(--main-brand-color);
      }
      &[data-sub_page_link_holder='true'] &-left svg {
        // fill: var(--main-brand-color);
        fill: var(--main-btn-text-color);
      }
      &[data-sub_page_link_holder='false'] &-right svg {
        display: none;
      }

      &:hover {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }

      &:first-child {
        margin: 0rem;
        margin-bottom: 0.5rem;
      }
      &:last-child {
        margin: 0rem;
        margin-top: 0.5rem;
      }

      &-left {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 2rem;
          // width: 1.5rem;
          fill: var(--main-text-color);
        }

        span {
          margin-left: 1rem;
          font-family: var(--main-font-primary);
          color: var(--main-text-color);
          font-size: 1.4rem;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 1.9rem;
          // fill: var(--main-brand-color);
          fill: var(--main-btn-text-color);
        }
      }
    }
  }
}
