@import '../../abstracts/variables.sass';

.messageBanner {
  height: 100vh;
  width: 100vw;
  background-color: $popup-overlay-color;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;

  &__message {
    border-radius: 1.5rem;
    background-color: $main-white-color;
    min-height: 50rem;
    width: 70rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;

    @media only screen and (max-width: 800px) {
      width: 80%;
      padding: 4rem 2rem;
    }

    &--icon--true {
      svg {
        height: 12rem;
        width: 12rem;
        fill: var(--main-brand-color);
      }
      margin-bottom: 3rem;
    }
    &--icon--false {
      svg {
        height: 12rem;
        width: 12rem;
        fill: $web-color-danger;
      }
      margin-bottom: 3rem;
    }

    &--title {
      h1 {
        color: var(--main-text-color);
        font-size: 2.8rem;
        font-weight: 800 !important;
        text-align: center;
      }
    }

    &--body {
      width: 90%;
      p {
        font-weight: 700 !important;
        font-size: 1.6rem;
        color: $main-light-gray-font-color-5;
        text-align: center;
        font-family: var(--main-font-secondary) !important;

        .learn-more-link {
          font-size: 1.5rem;
          font-family: var(--main-font-primary);
          font-style: normal;
          font-weight: 500 !important;
          line-height: 2.4rem;
          letter-spacing: 0.02em;
          text-align: center;
          color: $main-blue-hover;
          text-decoration: none;
          cursor: pointer;
        }

        .cancel-link {
          font-size: 1.4rem;
          font-family: var(--main-font-primary);
          font-style: normal;
          font-weight: 800 !important;
          line-height: 2.4rem;
          letter-spacing: 0.02em;
          padding: 1.3rem;
          text-align: center;
          color: $main-light-gray-font-color-5;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    &--button {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        &:nth-child(2) {
          margin-left: 2rem;
        }
        padding: 1rem 4rem;
      }
    }
  }
}
