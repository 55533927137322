@import '../abstracts/variables.sass';

.aiPhoneInput-outer {
  margin-top: 1.3rem;
  position: relative;

  label {
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 600 !important;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }
}

.aiPhoneInput__window {
  position: absolute;
  z-index: 2;
  height: 22rem;
  width: 25rem;
  bottom: -22.3rem;
  background-color: var(--input-background-color);
  border-radius: 0.8rem;
  padding: 1rem;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
  box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

  &--search {
    display: flex;
    align-items: center;
    transition: $normal-transition;
    background-color: var(--input-background-color);
    border: $input-border-1;
    border-radius: 0.8rem;
    height: 4rem;
    overflow: hidden;

    &:focus-within {
      -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
      box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

      @media only screen and (max-width: 850px) {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    &:hover {
      border: $input-border-2;
    }
    &-icon {
      margin-left: 1rem;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 2rem;
        height: 2rem;
        fill: #9b9ba1;
      }
    }

    input {
      flex-grow: 1;
      outline: none;
      background-color: var(--input-background-color);
    }
  }

  &--countries {
    height: 15rem;
    overflow-y: auto;
    margin-top: 0.5rem;
    &-country {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: $normal-transition;
      &:hover {
        background-color: #f4f4f5;
      }

      &-flag {
        font-size: 1.4rem;
        margin-right: 1rem;
      }

      &-name {
        font-size: 1.4rem;
        font-weight: 500;
        margin-right: 1rem;
      }

      &-code {
        font-size: 1.2rem;
        font-weight: 400;
        color: var(--main-light-gray-font-color-3);
      }
    }
  }
}

.aiPhoneInput {
  margin-top: 0.3rem;
  overflow: hidden;
  width: 100%;
  display: flex;

  input {
    outline: none;
    background-color: var(--input-background-color);
    padding-left: 1rem;
  }

  &__select {
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: $normal-transition;
    background-color: var(--input-background-color);
    border: $input-border-1;
    border-radius: 0.8rem;
    height: 4.5rem;
    margin-right: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;

    color: var(--main-text-color);
    font-weight: 500;

    &:hover {
      border: $input-border-2;
      -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
      box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

      @media only screen and (max-width: 850px) {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    &--country {
      font-size: 1.5rem;
      display: flex;
      align-items: center;

      &-country {
        margin-right: 0.5rem;
      }
      &-code {
      }
    }

    &--icon {
      &[data-is-country-window-open='true'] {
        svg {
          transform: rotate(90deg);
        }
      }
      svg {
        width: 1rem;
        height: 1rem;
        fill: var(--main-text-color);
        transform: rotate(270deg);
        transition: $normal-transition;
      }
    }
  }
  &-input {
    display: flex;
    align-items: center;
    flex-grow: 1;
    transition: $normal-transition;
    background-color: var(--input-background-color);
    border: $input-border-1;
    border-radius: 0.8rem;
    height: 4.5rem;
    &:focus-within {
      -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
      box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

      @media only screen and (max-width: 850px) {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    &:hover {
      border: $input-border-2;
    }

    input {
      color: var(--main-text-color);
      &:invalid[data-focused='true'] {
        color: $web-color-danger !important;
      }
    }
  }
}

.aiPhoneInput-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  height: 1rem;
  margin-left: 0.2rem;
}
