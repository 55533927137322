@import '../abstracts/variables.sass';

.aiURLInput-outer {
  margin-top: 1.3rem;

  label {
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 600 !important;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }
}

.aiURLInput {
  margin-top: 0.3rem;
  overflow: hidden;
  width: 100%;
  display: flex;

  span,
  input {
    outline: none;
    background-color: var(--input-background-color);
    padding-left: 1rem;
  }

  &-prefix {
    display: flex;
    align-items: center;
    transition: $normal-transition;
    border: $input-border-1;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    height: 4.5rem;
    border-right: none;
    background-color: var(--main-background-color);

    span {
      width: 7rem;
      color: #71717a;
      background-color: var(--main-background-color);
      // background-color: var(--sidebar-background-color);
    }
  }
  &-input {
    display: flex;
    align-items: center;
    flex-grow: 1;
    transition: $normal-transition;
    background-color: var(--input-background-color);
    border: $input-border-1;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    height: 4.5rem;
    &:focus-within {
      -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
      box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

      @media only screen and (max-width: 850px) {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    &:hover {
      border: $input-border-2;
    }

    input {
      color: var(--main-text-color);
      &:invalid[data-focused='true'] {
        color: $web-color-danger !important;
      }
    }
  }

  // main-phone-input
}

.aiURLInput-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  height: 1rem;
  margin-left: 0.2rem;
}
