@import '../abstracts/variables.sass';

.statusCard {
  background-color: var(--sidebar-background-color);
  border: 1px solid var(--main-border-color);
  padding: 2rem !important;
  border-radius: 0.8rem;
  width: 49%;

  @media only screen and (max-width: 1020px) {
    width: 100% !important;
  }

  &__content-holder {
    display: flex !important;
    align-items: center !important;
    gap: 2rem !important;
  }

  &__content {
    span {
      font-size: 1.35rem;
      font-weight: 500;
      color: var(--main-text-color);
      font-family: var(--main-font-primary);
    }
    h2 {
      font-size: 2.4rem;
      font-weight: 700;
      color: var(--main-text-color);
      font-family: var(--main-font-primary);
      margin-bottom: 0rem !important;
    }
    p {
      font-size: 1.2rem;
      font-weight: 400;
      color: $main-dark-gray-font-color;
      font-family: var(--main-font-primary);
      margin-top: 0rem !important;
      margin-bottom: 0 !important;
    }
  }

  &__inner-content-holder {
    display: flex !important;
    align-items: center !important;
    gap: 0.3rem !important;
  }

  &__icon {
    float: right;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: $main-dark-gray-font-color;
    }
  }

  &__image {
    img {
      max-width: 4rem !important;
    }
  }
}
