@import '../../abstracts/variables.sass';

.subPageListDropdown {
  padding: 0.5rem 0;
  position: absolute;
  background-color: var(--containers-background-color);
  border: $input-border-small;
  border-radius: $normal-border-radius;
  z-index: 2;
  transform: translate(-93%, 24%);
  /* right: 1rem;
  bottom: 3rem; */
  &__item {
    height: 5rem;
    min-width: 25rem;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    transition: $normal-transition;
    cursor: pointer;

    &:hover {
      background-color: var(--subPageList-hover-color);
    }

    &--left {
      margin-right: 1rem;
      svg {
        width: 1.7rem;
        fill: $main-light-gray-font-color-3;
      }
    }
    &--right {
      h3 {
        color: var(--main-text-color);
        font-weight: 600 !important;
        font-size: 1.3rem;
        line-height: 1.3rem;
        margin-bottom: 0.1rem;
        font-family: var(--main-font-primary);
      }
      p {
        color: $main-light-gray-font-color-3;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-family: var(--main-font-secondary) !important;
        margin-bottom: 0;
      }
    }
  }
}
