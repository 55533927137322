@import '../abstracts/variables.sass';

.copyToClipboard {
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;
  height: 6rem;
  padding: 0.5rem 1rem;
  border: $input-border-1;
  border-radius: 0.8rem;
  width: 100%;
  color: var(--main-text-color);
  transition: $normal-transition;
  background-color: var(--input-background-color);

  &[data-copied='true'] {
    border: 1px solid var(--main-brand-color);

    svg {
      fill: var(--main-brand-color);
    }
  }

  &__left {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    span:first-child {
      margin-bottom: 0rem;
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--main-text-color);
      transition: $normal-transition;
    }
    span:last-child {
      color: var(--main-text-color);
      font-size: 1.4rem;
      min-width: 50rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__right {
    height: 100%;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    @media only screen and (max-width: 1100px) {
      width: 4rem;
    }
    @media only screen and (max-width: 850px) {
      width: 5rem;
    }
    @media only screen and (max-width: 500px) {
      width: 6rem;
    }
    svg {
      cursor: pointer;
      fill: $main-light-gray-font-color-3;
      transition: $normal-transition;
      width: 100%;
      &:active {
        fill: var(--main-brand-color);
      }
    }
  }
}
