@import '../abstracts/variables.sass';

.customTable {
  border: $input-border-small;
  width: 100%;
  margin-bottom: 2rem;
  border-bottom-left-radius: $normal-border-radius;
  border-bottom-right-radius: $normal-border-radius;
  &__top {
    width: 100%;
    border-bottom: $input-border-small;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-x: auto;
    // -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    // scrollbar-width: none; /*Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    /* &::-webkit-scrollbar {
    display: none;
  } */

    display: flex;
    &--column {
      flex-grow: 1;
      &-head {
        min-height: 5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 2rem;
        border-bottom: $input-border-small;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        color: var(--main-text-color);
      }

      &-rows {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 5rem;
        padding-left: 2rem;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        overflow: hidden;
        /* width: 30rem; */

        p {
          margin-bottom: 0;
          line-height: 1.4rem;
          font-size: 1.4rem;
          font-weight: 400;
          color: var(--main-text-color);
          font-family: var(--main-font-secondary) !important;
        }

        &-textholder {
          display: flex;
          align-items: center;

          &-changeableField {
            margin-left: 0.5rem;
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
          }
        }

        img {
          /* height: 90%;
          width: 90%; */
          height: 50%;
          width: 50%;
          object-fit: cover;
        }
      }
    }
  }

  &__bottom {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    &--left {
      color: var(--main-text-color);
      p {
        font-size: 1.33rem;
        font-weight: 500;
        font-family: var(--main-font-secondary) !important;
        text-align: left;
      }

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--main-text-color);
      }
    }
    &--right {
      color: var(--main-text-color);
      p {
        font-size: 1.33rem;
        font-weight: 500;
        font-family: var(--main-font-secondary) !important;
        text-align: right;
      }

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--main-text-color);
      }
    }
  }
}
