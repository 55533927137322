@import '../../abstracts/variables.sass';

.filterTheme2 {
  width: 100%;
  margin-top: 2rem;
  // margin-bottom: 2rem;
  &__top {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    overflow-x: auto;

    @media only screen and (max-width: 750px) {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    span {
      padding-right: 2rem;
      padding-left: 2rem;
      padding-bottom: 1rem;
      border-bottom: 2px solid #b5bac4;
      color: #8b909a;
      font-size: 1.4rem;
      cursor: pointer;
      white-space: nowrap;
      height: 3.4rem;
      transition: all 0.3s ease;
      text-transform: capitalize;
    }

    &--selected {
      border-bottom: 2px solid #3498db !important;
      color: #3498db !important;
    }
    &--hidden {
      flex-grow: 1;
      color: transparent !important;
      cursor: default !important;
      height: 3.4rem;
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input,
    select {
      color: #8b909a;
      padding: 0rem 1rem;
      background-color: var(--sidebar-background-color);
    }

    select {
      cursor: pointer;
      border: 1px solid var(--subPageLinkHolder-link-hover-color);
      background-color: var(--sidebar-background-color);
      padding: 0rem 1rem;
      border-radius: 0.5rem;
      box-shadow: 0px 4px 16px 0px #0000000a;
      height: 4rem;
      outline: none;
      max-width: 20rem;
      @media only screen and (max-width: 750px) {
        width: 45%;
      }
    }

    &--search {
      display: flex;
      align-items: center;
      border: 1px solid var(--subPageLinkHolder-link-hover-color);
      border-radius: 0.5rem;
      background-color: var(--sidebar-background-color);
      box-shadow: 0px 4px 16px 0px #0000000a;
      height: 4rem;
      padding-right: 1rem;
      max-width: 20rem;
      margin-right: 1rem;
      @media only screen and (max-width: 750px) {
        width: 45%;
      }
      input {
        color: var(--main-text-color);
        padding: 0rem 1rem;
        padding-right: 0;
        background-color: var(--sidebar-background-color);
        outline: none;
        @media only screen and (max-width: 750px) {
          width: 100%;
        }
      }

      svg {
        fill: #8b909a;
        height: 1.5rem;
        width: 1.5rem;
        @media only screen and (max-width: 750px) {
          display: none;
        }
      }
    }
  }
}
