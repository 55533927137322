@import '../abstracts/variables.sass';

.valueSelector {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      height: 3.2rem;
      width: 3.2rem;
      border-radius: 50%;
      background-color: var(--main-btn-color);
      color: var(--main-btn-text-color);
      cursor: pointer;
      transition: all 0.3s;
      font-weight: 500;
      border: 0.5px solid var(--main-btn-color);
      font-family: var(--main-font-primary) !important;

      &:hover {
        opacity: 0.9;
      }

      &--light {
        background-color: var(--input-background-color);
        color: var(--main-text-color);
        border: 0.5px solid var(--input-border-color);
      }

      &--loading {
        background: linear-gradient(
          95deg,
          #c3c3c3be 25%,
          #dfdfdf 50%,
          #e8e8e891 75%
        );
        color: var(--main-text-color);
        background-size: 200% 100%;
        animation: loadingAnimation 1.5s infinite;
        cursor: not-allowed;
      }
    }
  }

  @keyframes loadingAnimation {
    0% {
      background-position: 110% 0;
    }
    100% {
      background-position: -110% 0;
    }
  }
  &__value {
    word-wrap: none;
    white-space: nowrap;
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
  }
}
