@import '../../../abstracts/variables.sass';

.taraCreateNewChat {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-family: var(--main-font-primary) !important;
    margin-top: -0.7rem;
    cursor: pointer;
    background-color: var(--main-btn-color);
    padding: 0.8rem 1.8rem;
    border-radius: 0.7rem;
    border: 0.5px solid var(--main-btn-color);
    transition: $normal-transition;
    color: var(--main-btn-text-color);
    font-size: 1.2rem;
    font-weight: 400;
    flex-grow: 1;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 1.3rem;
      height: 1.3rem;
      fill: var(--main-btn-text-color);
    }
  }

  &[data-is-sidebar-minimized-new-chat='true'] {
    .taraCreateNewChat__content {
      height: 4.3rem;
      width: 4rem;
      padding: 0;

      svg {
        width: 1.3rem;
        height: 1.3rem;
        fill: var(--main-btn-text-color);
      }
      div {
        display: none;
      }
    }
  }
}
