@import '../../abstracts/variables.sass';

.customTimeRangePicker {
  padding: 1rem;
  padding-bottom: 0;
  &__text {
    h3 {
      font-size: 1.7rem;
      font-family: var(--main-font-primary);
      color: var(--main-text-color);
      font-weight: 500;
      line-height: 1.5rem;
    }
    margin-bottom: 2rem;
  }

  &__dates {
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-bottom: 2rem;

    span {
      color: var(--main-text-color);
      margin: 1rem;
      font-size: 2rem;
      font-weight: 500;
    }
  }

  &__message {
    font-size: 1.3rem;
    font-family: var(--main-font-primary);
    color: $web-color-danger;
    font-weight: 400;
    line-height: 1.2rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &__btn {
    display: flex;
    align-items: center;
    // justify-content: center;
  }
}
