@import '../../abstracts/variables.sass';

.recommendations {
  &--all {
    padding-top: 1px;
  }

  margin-top: 3rem;
  h2 {
    font-size: 1.9rem;
    font-weight: 700;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
    margin-bottom: 0.5rem !important;
  }

  h3 {
    font-size: 1.6rem;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
    margin-bottom: 2rem;
  }
}
/* d */
.recommendationItem__container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1%;
  position: relative;
  &--overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
    border-radius: 0.8rem !important;
    -webkit-box-shadow: 0px 0px 15px -3px rgba(14, 18, 39, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(14, 18, 39, 0.25);
  }
  @media only screen and (max-width: 650px) {
    gap: 0;
  }

  &-none {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.recommendationItem {
  background-color: var(--sidebar-background-color);
  width: 32%;
  max-width: 50rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border: 1px solid var(--main-border-color);
  border-radius: 0.8rem;
  transition: $normal-transition;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
  box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow: hidden;
  padding: 2rem;

  @media only screen and (max-width: 1250px) {
    width: 49%;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }

  &[data-is-recommendation-item-expanded='true'] {
    .recommendationItem__top--content-right-icon {
      svg {
        transform: rotate(90deg);
      }
    }

    .recommendationItem__bottom {
      padding-top: 2rem;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--title {
      h2 {
        margin-top: 0.5rem;
        font-size: 1.7rem;
        font-weight: 600 !important;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0;
      }

      h3 {
        font-size: 1.3rem;
        color: $main-light-gray-font-color-5;
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0;
        font-weight: 400 !important;
      }

      &-impact {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        color: #16a34a;
        border: 0.5px solid #16a34a;
        // background-color: #dcfce7;
        padding: 0.3rem 1rem;
        border-radius: 1rem;
        margin-top: 1rem;
        max-width: 11rem;

        div {
          font-size: 1.2rem;
          font-weight: 500;
          font-family: var(--main-font-primary) !important;
          color: #16a34a;
          text-transform: uppercase;
        }

        span {
          font-size: 1.2rem;
          font-weight: 500;
          font-family: var(--main-font-primary) !important;
          color: #16a34a;
        }

        &[data-recommendation-impact='false'] {
          border-color: #dc2626;
          // background-color: #fee2e2;

          div {
            color: #dc2626;
          }

          span {
            color: #dc2626;
          }
        }
      }
    }

    &--icon {
      align-self: self-start;
      margin-left: 1rem;
      height: 2.8rem;
      width: 2.8rem;
      border-radius: 0.7rem;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: $normal-transition;

      &:hover {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: var(--main-text-color);
        transform: rotate(270deg);
        transition: $normal-transition;
      }
    }
  }

  &__center {
    margin-top: 1rem;
    &--content {
      padding: 1.5rem;
      background-color: var(--subPageLinkHolder-link-hover-color);
      border-radius: 0.5rem;

      &-description {
        &-title {
          font-size: 1.6rem;
          font-weight: 500;
          color: var(--main-text-color);
          font-family: var(--main-font-primary) !important;
          margin-bottom: 0.5rem;
        }

        &-sentence {
          font-size: 1.5rem;
          color: #7c7c7c;
          font-family: var(--main-font-primary) !important;
        }
      }
    }
  }

  &__bottom {
    transition: $normal-transition;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    &--preview {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-current {
        margin-bottom: 1.5rem;
      }

      &-text {
        font-size: 1.5rem;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        font-weight: 500;
        margin-right: 0.5rem;
      }
      &-color {
        display: flex;
        align-items: center;
        padding: 0.5rem 0rem;
        margin-right: 0.5rem;

        span {
          &:first-child {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            border: 1px solid #000000;
          }
          &:last-child {
            font-size: 1.7rem;
            color: var(--sub-text-color);
            font-family: var(--main-font-primary) !important;
            font-weight: 500;
            margin-left: 1rem;
          }
        }
      }
      &-box {
        color: var(--sub-text-color);
        font-size: 1.6rem;
        // border: 1px solid var(--sub-text-color);
        border: 1px solid transparent;
        padding: 0.5rem 2rem;
        border-radius: 0.5rem;
        background-color: var(--subPageLinkHolder-link-hover-color);
        margin-right: 0.5rem;
      }

      &-other {
        color: var(--sub-text-color);
        font-family: var(--main-font-primary) !important;
        font-size: 1.7rem;
        font-weight: 500;
        padding: 0.5rem 0rem;
        margin-right: 0.5rem;
      }
    }

    //scenarios
    &--scenarios {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 2rem;
      font-family: var(--main-font-primary) !important;
      border-top: 1px solid var(--main-border-color);
      padding-top: 2rem;
      gap: 1rem;
    }
    &--scenario {
      width: 100%;
      padding: 1.5rem;
      background-color: var(--subPageLinkHolder-link-hover-color);
      border-radius: 0.5rem;
      &-title {
        font-size: 1.5rem;
        font-weight: 500;
        color: var(--main-text-color);
        margin-bottom: 0.5rem;
      }

      &-items {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        &-item {
          display: flex;
          align-items: center;

          &-key {
            margin-right: 0.5rem;
            font-size: 1.3rem;
            color: var(--main-text-color);
            font-weight: 500;
          }
          &-value {
            margin-right: 0.5rem;
            font-size: 1.3rem;
            color: var(--main-text-color);
            font-weight: 400;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 2rem;

    img {
      width: 2.5rem;
      height: 2.5rem;
    }

    &-btn {
      width: max-content;
      font-size: 1.5rem;
      font-family: var(--main-font-primary) !important;
      font-weight: 500;
      border: 1px solid $main-light-gray-font-color-5;
      padding: 0.7rem 1rem;
      border-radius: 0.8rem;
      cursor: pointer;
      transition: $normal-transition;
      margin-top: 1rem;
      width: 45%;
    }

    &-accept-btn {
      color: var(--main-btn-text-color);
      background-color: #22c55e;
      border: 1px solid #22c55e;
      &:hover {
        background-color: #1eaf54;
        border: 1px solid #1eaf54;
      }
    }
    &-pause-btn {
      color: var(--main-btn-text-color);
      background-color: #757575;
      &:hover {
        background-color: #616161;
      }
    }
    &-resume-btn {
      color: var(--main-btn-text-color);
      background-color: #28afc7;
      &:hover {
        background-color: #2293a7;
      }
    }

    &-decline-btn {
      color: #e2352c;
      background-color: transparent;
      &:hover {
        color: #be2d26;
      }
    }

    &-reject-btn {
      color: #5f5f5f;
      background-color: transparent;
      &:hover {
        color: #888888;
      }
    }
  }
}
