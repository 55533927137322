@import '../../abstracts/variables.sass';

.quickOnboardingMain {
  /*   padding: 2rem 0rem;
  padding-top: 4rem; */
  background-color: var(--sidebar-background-color);
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 850px) {
    padding: 0;
  }

  &__content {
    width: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;

    @media only screen and (max-width: 450px) {
      width: 100%;
    }

    &--logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;

      img {
        height: 5rem;
      }
    }

    &--top {
      width: 100%;
      margin-bottom: 3rem;
      padding-top: 3rem;
    }
    &--bottom {
      // max-height: 84vh;
      width: 40rem;
      padding: 2.3rem;
      // background-color: var(--sidebar-background-color);
      // background-color: var(--main-background-color);
      // background-color: #f4f4f5;
      // border: 1px solid $main-light-gray-font-color-2;
      // background-color: var(--sidebar-background-color);
      // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      // box-shadow: $normal-box-shadow-plus;
      border-radius: 0.8rem;
      padding-bottom: 2.5rem;

      @media only screen and (max-width: 450px) {
        width: 100%;
      }

      /*  @media only screen and (max-width: 850px) {
        box-shadow: none;
      } */
    }
  }
}
