@import '../abstracts/variables.sass';

.normalForm {
  padding-top: 2rem;
  width: 100%;

  &--wrapper {
    width: 100%;
    border: 1px solid var(--main-border-color);
    background-color: var(--sidebar-background-color);
    padding: 2rem;
    border-radius: 0.8rem;
  }

  &_spanText {
    font-family: var(--main-font-primary);
    color: var(--main-text-color);
    font-size: 1.3rem;
  }

  &__center {
    margin-top: 2.5rem;
  }

  &__top,
  &__center {
    h1 {
      margin-bottom: 2rem;
    }
    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500 !important;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }
    h2,
    h3,
    ol,
    ul,
    strong {
      font-family: var(--main-font-primary);
      color: var(--main-text-color);
    }

    h3 {
      font-weight: 400 !important;
    }

    hr {
      width: 100%;
      height: 0.1rem;
      border-bottom: 0.05rem solid var(--containers-border-color);
      background-color: transparent;
    }

    h1,
    h2,
    h3,
    ol,
    ul,
    span {
      color: var(--main-text-color);
    }

    code {
      color: #d3d3d3;
      span {
        color: #d3d3d3;
      }
    }

    &--titleWithBtn {
      display: flex;
      align-items: center;
      margin-top: 1.6rem;

      h1 {
        margin: 0;
        font-size: 1.9rem;
        color: var(--main-text-color);
        font-weight: 600;
        line-height: 2rem;
        margin-right: 2rem;
      }
    }

    &--container1 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }

      &-left {
        flex-grow: 1;
        flex: 1;
        margin-right: 2rem;
        @media only screen and (max-width: 600px) {
          margin-right: 0rem;
          margin-bottom: 2rem;
          width: 100%;
        }
      }

      &-center {
        flex-grow: 1;
        flex: 1;
        margin-right: 2rem;
        @media only screen and (max-width: 600px) {
          margin-right: 0rem;
          margin-bottom: 2rem;
          width: 100%;
        }
      }

      &-right {
        flex-grow: 1;
        flex: 1;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }

    .learn-more-link {
      font-weight: 500 !important;
      color: $main-blue-hover;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__bottom {
    margin-top: 2rem;
  }
}

.normalForm-margin-bottom-0 {
  flex-grow: 1;
  flex: 1;
  margin-right: 2rem;
  @media only screen and (max-width: 600px) {
    margin-right: 0rem;
    margin-bottom: 0rem !important;
    width: 100%;
  }
}

.normalForm-margin-bottom-2rem {
  margin-bottom: 2rem;
}

.normalForm-table-heading {
  /* text-align: center; */
  font-size: 1.5rem;
  color: var(--main-text-color);
  font-weight: 500;
  margin-top: 4rem;
  border-top-left-radius: $normal-border-radius;
  border-top-right-radius: $normal-border-radius;
  border-top: $input-border-small;
  border-left: $input-border-small;
  border-right: $input-border-small;
  padding: 1rem;
}

.normalForm-dataGridContainer {
  /* height: 28rem; */
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: $input-border-small;
  border-bottom-left-radius: $normal-border-radius;
  border-bottom-right-radius: $normal-border-radius;

  /*----------DISABLE MUI PAGINATION STYLES START ----------- */
  .css-17jjc08-MuiDataGrid-footerContainer,
  .MuiDataGrid-footerContainer {
    display: none !important;
  }
  /*----------DISABLE MUI PAGINATION STYLES END ------------- */
}

.normalForm__titleContainer--With-marginBottom {
  width: 100%;
  border-bottom: $input-border-small;
  h1 {
    margin: 0;
    font-size: 1.9rem;
    color: var(--main-text-color);
    font-weight: 600;
    line-height: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
}

.normalForm__container1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  &--left {
    width: 50%;
    margin-right: 0.5rem;
    @media only screen and (max-width: 1000px) {
      margin-right: 0;
      width: 100%;
    }
  }
  &--right {
    width: 50%;
    margin-left: 0.5rem;
    @media only screen and (max-width: 1000px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.normalForm__container2 {
  width: 100%;
  display: flex;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
  &--left {
    display: flex;
    width: 100%;
    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      margin-right: 1rem;
    }
    &-1 {
      width: 100%;
      margin-right: 1rem;
    }
    &-2 {
      width: 100%;
      margin-right: 1rem;
    }
  }
  &--right {
    width: 100%;
    display: flex;
    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }
    &-1 {
      width: 100%;
      margin-right: 1rem;
    }
    &-2 {
      width: 100%;
    }
  }
}

.normalForm__container3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  /* align-items: center;
  justify-content: space-around; */
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  &--item {
    min-width: calc(50% - 0.5rem);
    // &:nth-child(odd) {
    //   margin-left: 0;
    //   margin-right: 0.5rem;
    // }

    // &:nth-child(even) {
    //   margin-left: 0.5rem;
    //   margin-right: 0;
    // }
    // @media only screen and (max-width: 1000px) {
    //   &:nth-child(odd) {
    //     margin-left: 0;
    //     margin-right: 0;
    //   }

    //   &:nth-child(even) {
    //     margin-left: 0;
    //     margin-right: 0;
    //   }
    //   width: 100%;
    // }

    &-content {
      width: 100%;
    }
  }
}

.normalForm__AI__button {
  &-initial {
    div {
      max-width: fit-content;
      font-size: 1.1rem;
      font-weight: 500;
      color: #fff;
      margin-left: 1rem;
      padding: 0.5rem 1rem;
      background-color: $web-color-success;
      cursor: pointer;
      border-radius: 0.5rem;

      &:hover {
        border: $input-border-2;
      }
    }
  }

  &-error {
    background-color: $web-color-danger !important;
  }

  &-warning {
    background-color: $web-color-orange !important;
  }
}

.normalForm__switches {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.normalForm__switch {
  width: 20%;
  margin-right: 2rem;
  @media only screen and (max-width: 1100px) {
    width: 40%;
  }
}

.normalForm__icon--message {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  svg {
    margin-right: 0.5rem;
    fill: var(--main-text-color);
    height: 1.4rem;
    width: 1.4rem;
  }

  span {
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--main-text-color);
  }
}
