@import '../abstracts/variables.sass';

.categoryCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  border: $input-border-small;
  box-shadow: $normal-box-shadow;
  background-color: var(--sidebar-background-color);
  border-radius: $normal-border-radius;
  height: 20rem;
  width: 30rem;
  margin: 2rem;
  cursor: pointer;
  transition: $normal-transition;

  &__isSelected {
    border: 3.5px solid var(--main-brand-hover-color);
    svg {
      fill: var(--main-brand-hover-color);
    }
    p {
      color: var(--main-brand-hover-color);
      font-family: var(--main-font-secondary) !important;
    }
    span {
      color: var(--main-brand-hover-color);
    }
  }

  &:hover {
    border-color: var(--main-brand-hover-color);
    svg {
      fill: var(--main-brand-hover-color);
    }
    p {
      color: var(--main-brand-hover-color);
      font-family: var(--main-font-secondary) !important;
    }
    span {
      color: var(--main-brand-hover-color);
    }
  }

  &__icon {
    margin-bottom: 1rem;
    svg {
      width: 5rem;
      height: 5rem;
      fill: var(--icon-color);
    }
  }

  &__titles {
    text-align: center;
    color: var(--icon-color);
    p {
      margin-bottom: 0;
      display: block;
      text-transform: uppercase;
      font-family: var(--main-font-primary) !important;
      font-size: 1.9rem;
      font-weight: 500 !important;
      letter-spacing: 1px;
    }
    span {
      font-size: 1.4rem;
    }
  }
}
