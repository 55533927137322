@import '../abstracts/variables.sass';

.squareGrayButton {
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-family: var(--main-font-primary);
  transition: $normal-transition;
  background-color: var(--gray-btn-light-background-color);
  color: var(--sub-text-color);
  border: $input-border-small;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.3rem;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-square-gray-button-normal='true'] {
    border-radius: 0.7rem;
    border-color: transparent;
  }

  svg {
    transition: $normal-transition;
    height: 1.4rem;
    margin-right: 0.3rem;
    fill: var(--sub-text-color);
  }
  p {
    margin-bottom: 0;
    font-family: var(--main-font-secondary) !important;
  }
  &[data-square-gray-button-normal='true']:hover {
    background-color: var(--gray-btn-light-hover-background-color);
    color: var(--sub-text-color);
    svg {
      fill: var(--sub-text-color);
    }
  }
}
