@import '../abstracts/variables.sass';

.aiSelectInput-outer {
  margin-top: 1.3rem;
  label {
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 600 !important;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }
}

.aiSelectInput {
  position: relative;
  overflow: hidden;
  border: $input-border-1;
  border-radius: 0.8rem;
  height: 4.5rem;
  margin-top: 0.3rem;
  width: 100%;
  transition: $normal-transition;
  display: flex;
  flex-direction: column;
  background-color: var(--input-background-color);

  &:hover {
    border: $input-border-2;
  }

  &:focus-within {
    -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

    @media only screen and (max-width: 850px) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    label {
      color: $main-light-gray-font-color-3;
      font-family: var(--main-font-primary) !important;
    }
  }

  label {
    margin-bottom: 0rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--main-text-color);
    padding-left: 1rem;
    padding-top: 0.5rem;
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }

  select,
  select:active,
  select:focus {
    outline: none;
    border: none;
    background-color: var(--input-background-color);
    padding-left: 1rem !important;
  }

  select:disabled {
    cursor: not-allowed;
    color: var(--gray-btn-light-text-color);
  }

  select {
    width: 100%;
    height: 100%;
    color: var(--main-text-color);
    // font-weight: 500;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
  }
}

.aiSelectInput-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--main-text-color);
  height: 1rem;
  margin-left: 0.2rem;
}
