@import '../../abstracts/variables.sass';
.trackingIntegration {
  @media only screen and (max-width: 750px) {
    margin-top: 2rem;
  }
  &-wrapper {
    background-color: var(--sidebar-background-color);
    border: 1px solid var(--main-border-color);
    padding: 2rem !important;
    // padding-top: 0 !important;
    border-radius: 0.8rem;
  }

  &-title {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary);
    margin-bottom: 0;
  }

  &-subtitle {
    font-size: 1.4rem;
    font-weight: 400;
    color: $main-dark-gray-font-color;
    transition: $normal-transition;
    font-family: var(--main-font-primary);
    margin-top: 0.5rem;
  }
  &-subtitle-2 {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary);
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  &-list {
    margin-bottom: -1.5rem;

    li {
      list-style: decimal;
      color: var(--main-text-color);
      margin-left: 1.5rem;
      font-family: var(--main-font-primary);
      font-size: 1.5rem;
      line-height: 2.5rem;

      strong {
        color: var(--main-text-color);
      }
    }
  }

  &-status {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    padding-top: 1rem;
  }

  &--titleWithSwitch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    @media only screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
    }

    &-switch {
      @media only screen and (max-width: 550px) {
        margin-bottom: 1rem;
      }
    }
  }
  .disabled {
    opacity: 0.4 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
}
