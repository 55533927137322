@import '../abstracts/variables.sass';

.blurAndCopyInput {
  position: relative;
  display: flex;
  align-items: center;
  border: $input-border-1;
  border-radius: 0.8rem;
  height: 6rem;
  margin-top: 1.6rem;
  width: 100%;
  transition: $normal-transition;
  background-color: var(--input-background-color);
  &:hover {
    border: $input-border-2;
  }

  &:focus-within {
    -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    transition: $normal-transition;

    @media only screen and (max-width: 850px) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .blurAndCopyInput__left--icon > svg {
      fill: var(--main-brand-color);
    }
  }

  &__left {
    margin-left: 2rem;
    margin-right: 2rem;
    svg {
      height: 2.5rem;
      width: 3rem;
      transition: $normal-transition;
    }
  }

  &__right {
    width: 100%;
    display: flex;
    flex-flow: column-reverse wrap;

    input {
      transition: all 0.3s ease;
      width: 90%;
      filter: none;
    }

    &[data-is-value-blurred='true'] {
      input {
        filter: blur(3px);
        cursor: not-allowed;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Standard syntax */
      }
    }

    label {
      margin-bottom: 0rem;
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
    }

    input,
    input:active,
    input:focus {
      outline: none;
      background-color: var(--input-background-color);
      color: var(--main-text-color);
    }

    input::selection {
      background-color: transparent;
      color: var(--main-text-color);
    }

    input:invalid[data-focused='true'] ~ label {
      color: $web-color-danger !important;
      font-family: var(--main-font-primary) !important;
    }

    /* input:focus + .blurAndCopyInput > .blurAndCopyInput__left > svg {
      fill: $main-light-gray-font-color-3;
    } */
  }

  &__actions {
    display: flex;
    align-items: center;
    position: absolute;
    background-color: var(--input-background-color);
    right: 1rem;
    top: 0;
    bottom: 0;
    padding-left: 1rem;
  }

  &__visibility {
    cursor: pointer;

    svg {
      height: 1.8rem;
      width: 1.8rem;
    }

    &--true {
      svg {
        fill: #888888;
      }
    }

    &--false {
      svg {
        fill: #888888;
      }
    }
  }

  &__copyToClipboard {
    margin-left: 1.5rem;
    cursor: pointer;
    svg {
      height: 1.8rem;
      width: 1.8rem;
      fill: $web-color-info;
    }
  }
}

.blurAndCopyInput-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  height: 1rem;
  // margin-left: 0.1rem;
}
