@import '../../../abstracts/variables.sass';

.taraSearchInput {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 0.5px solid var(--main-border-color);
  padding: 1rem 0rem 2rem 2rem;
  @media only screen and (max-width: 750px) {
    padding: 1rem 2rem;
  }
  &__left {
    svg {
      fill: $main-dark-gray-font-color;
      height: 2rem;
      width: 2rem;
      margin-bottom: -0.5rem;
    }
  }
  &__right {
    flex-grow: 1;
    input,
    input:active,
    input:focus {
      outline: none;
      background-color: transparent;
      font-family: var(--main-font-primary);
    }

    input {
      width: 100%;
      font-size: 1.5rem;
      color: var(--main-text-color);
    }
  }
}
