@import '../../abstracts/variables.sass';

.showInfoBlock {
  height: 3rem;
  width: 2rem;
  cursor: pointer;
  margin-left: 1rem;
  position: relative;
  transition: $normal-transition;
  list-style-type: none !important;

  &[data-info_block='true'] svg {
    fill: var(--main-brand-color);
  }

  svg {
    height: 100%;
    width: 100%;
    fill: $main-light-gray-font-color-5;
    transition: $normal-transition;

    /* &:hover {
      fill: var(--main-brand-color);
    } */
  }

  &__infoHolder {
    position: absolute;
    width: 20rem;
    max-height: 20rem;
    overflow-y: scroll;
    padding: 1rem;
    border-radius: 0.8rem;
    box-shadow: $normal-box-shadow;
    background-color: var(--containers-background-color);
    color: var(--main-text-color);
    font-size: 1.2rem;
    z-index: 100;
    right: 2rem;
    top: 1rem;
    border: $input-border-small;
    transition: $normal-transition;

    -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  /* &[data-info_block='true'] > &__infoHolder {
    display: block;
  } */

  &:hover > &__infoHolder {
    display: block;
  }
}
