@import '../../../abstracts/variables.sass';

.taraHistorySidebar {
  width: 100%;

  &[data-is-sidebar-minimized-history='true'] {
    .taraHistorySidebar--empty {
      display: none;
    }
    .taraHistorySidebar--viewAll {
      display: none;
    }

    .taraHistorySidebar--title1 {
      display: none;
    }

    .taraHistorySidebar__itemContainer {
      display: none;
    }
  }

  &--viewAll {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: $normal-transition;

    span {
      font-family: var(--main-font-primary) !important;
      color: var(--main-text-color);
      font-size: 1.3rem;
      font-weight: 400;
    }

    svg {
      width: 1.3rem;
      height: 1.3rem;
      fill: var(--main-text-color);
    }

    opacity: 0.6 !important;
    &:hover {
      opacity: 1 !important;
    }
  }

  &--empty {
    text-align: center;
    font-family: var(--main-font-primary);
    color: var(--main-text-color);
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 2rem;
  }

  &--title1,
  &--title2 {
    width: 100%;
    text-align: left;

    span {
      margin-left: 1rem;
      font-family: var(--main-font-primary);
      // color: #8b909a;
      color: var(--main-text-color);
      font-size: 1.1rem;
      font-weight: 500;
      //   text-transform: uppercase;
    }
  }

  &--title1 {
    margin-bottom: 0.5rem;
  }
  &--title2 {
    margin-top: 1.5rem;
  }

  &--linkItem {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //   border: $white-border;
    background-color: var(--sidebar-background-color);
    border-radius: $normal-border-radius;
    transition: $normal-transition;
    padding: 0rem 1rem;
    margin: 0.25rem 0rem;
    cursor: pointer;

    &[data-sub_page_link_holder='true'] {
      background-color: var(--subPageLinkHolder-link-hover-color);

      span {
        color: var(--main-text-color);
      }
    }
    &:hover[data-sub_page_link_holder='true'] {
      background-color: var(--subPageLinkHolder-link-hover-color);
    }
    &[data-sub_page_link_holder='true'] {
      .taraHistorySidebar--linkItem-left-svg svg {
        fill: var(--main-text-color);
      }
    }
    &[data-sub_page_link_holder='false'] &-right svg {
      display: none;
    }

    &:hover {
      background-color: var(--subPageLinkHolder-link-hover-color);
    }

    &:first-child {
      margin: 0rem;
      margin-bottom: 0.5rem;
    }
    &:last-child {
      margin: 0rem;
      margin-top: 0.5rem;
    }

    &-left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-svg {
        position: relative;
        margin-top: 0.7rem;
        svg {
          width: 1.6rem;
          height: 1.6rem;
          // fill: #8b909a;
          fill: var(--main-text-color);
        }
      }

      span {
        margin-right: 1rem;
        font-family: var(--main-font-primary);
        // color: #8b909a;
        color: var(--main-text-color);
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
  }
}
