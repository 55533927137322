@import '../abstracts/variables.sass';

.currencyInput {
  position: relative;
  overflow: hidden;
  border: $input-border-1;
  border-radius: 0.8rem;
  height: 6rem;
  margin-top: 1.6rem;
  width: 100%;
  transition: $normal-transition;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  background-color: $main-white-color;
  flex-flow: column-reverse wrap;

  &:hover {
    border: $input-border-2;
  }

  &:focus-within {
    -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

    @media only screen and (max-width: 850px) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    label {
      font-family: var(--main-font-primary) !important;
      color: $main-light-gray-font-color-3;
    }
  }

  label {
    margin-bottom: 0rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--main-text-color);
    padding-left: 1rem;
    padding-top: 0.5rem;
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
    @media only screen and (max-width: 325px) {
      padding-top: 0.2rem;
    }
  }

  input,
  input:active,
  input:focus {
    outline: none;
    background-color: $main-white-color;
    padding-left: 1rem;
  }

  input {
    width: 90%;
    margin-bottom: 1rem;
    color: transparent;
  }

  input:invalid[data-focused='true'] ~ label {
    color: $web-color-danger !important;
    font-family: var(--main-font-primary) !important;
  }

  input:focus + .loginInput > .loginInput__left > svg {
    fill: $main-light-gray-font-color-3;
  }
}

.currencyInput-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  height: 1rem;
  margin-left: 0.2rem;
}

.currencyInput-mask {
  position: absolute;
  margin-bottom: 1rem;
  padding-left: 1rem;
  color: var(--main-text-color);
  font-size: 1.4rem;
  font-weight: 400;
}

/* input-element-holder relative'>
        <input
          ref={ref}
          id={id}
          className={
            'w-full peer border border-solid border-[#dbdbdb] rounded-lg h-[60px] p-2 pb-1 outline-none focus:outline-none focus:border-[#cecece] transition-all duration-500 hover:border-[#cecece]' +
            ' ' +
            classes
          }
          type={type}
          value={innerValue}
          onChange={handleChangeValue}
          onFocus={handleFocusElement}
          required={required}
        />
        <label
          className='absolute top-2 left-3 text-xs peer-focus:text-gray-400 font-medium transition-all' */
