@import '../../../abstracts/variables.sass';

.taraSearchItem {
  background-color: var(--sidebar-background-color);
  border: 1px solid var(--main-border-color);
  padding: 1rem !important;
  border-radius: 0.8rem;
  transition: $normal-transition;
  box-shadow: none;

  &:hover {
    box-shadow: $normal-box-shadow;
  }

  &__top {
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 1rem;
    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--main-text-color);
      font-family: var(--main-font-primary);
      margin-bottom: 0;
      white-space: nowrap;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      color: $main-dark-gray-font-color;
      font-family: var(--main-font-primary);
      margin-bottom: 0;
      white-space: nowrap;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 0.5px solid var(--main-border-color);
    span {
      font-size: 1.3rem;
      font-weight: 400;
      color: $main-dark-gray-font-color;
      transition: $normal-transition;
      font-family: var(--main-font-primary);
    }
    &--action {
      position: relative;
      cursor: pointer;

      &-svg {
        svg {
          fill: var(--main-text-color);
          height: 2rem;
          width: 2rem;
          transform: rotate(90deg);
        }
      }

      .taraHistoryItemDropdown {
        right: 0rem !important;
        top: 2rem !important;
      }
    }
  }
}
