@import '../../abstracts/variables.sass';

.topbar {
  height: $header-height;
  width: 100%;
  // width: calc(100% - $sidebar-width);
  /* background-color: var(--topbar-background-color); */
  background-color: var(--main-brand-color);
  align-items: center;
  justify-content: space-between;
  top: 0;
  right: 0;
  padding-left: 10%;
  padding-right: 10%;
  position: fixed;
  -webkit-box-shadow: -15px 11px 15px -22px rgba(0, 0, 0, 0.59);
  box-shadow: -15px 11px 15px -22px rgba(0, 0, 0, 0.59);
  display: none;
  z-index: 4;

  @media only screen and (max-width: 750px) {
    padding-left: 2rem;
    padding-right: 2rem;
    left: 0;
    display: flex;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 2.5rem;
      height: 2.5rem;
      // fill: $main-light-gray-font-color-3;
      fill: var(--main-btn-text-color);
      cursor: pointer;
    }
  }

  &__center {
    max-width: 7rem;
    max-height: 7rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      // height: 100%;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    &--notification {
      svg {
        width: 2.2rem;
        height: 2.2rem;
        fill: $main-light-gray-font-color-3;
      }
    }

    &--usermenu {
      height: 3.5rem;
      width: 3.5rem;
      border-radius: 50%;
      overflow: hidden;
      border: $input-border-1;
      margin-left: 1em;
      img {
        object-fit: cover;
      }
    }
  }
}
