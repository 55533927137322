@import '../../abstracts/variables.sass';

.subPageInnerContentHolder {
  &__content {
    background-color: var(--containers-background-color);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    /* border: 0.5px solid var(--containers-border-color);
    border-radius: 0.8rem; */
    /* border-radius: 0.8rem;
      -webkit-box-shadow: $normal-box-shadow;
      box-shadow: $normal-box-shadow; */

    @media only screen and (max-width: 750px) {
      width: 100%;
    }

    /*   .line {
      padding: 0 2rem;
      div {
        width: 100%;
        height: 0.1px;
        background-color: var(--containers-border-color);
      }
    } */

    &--top {
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: $input-border-small;
      margin-left: 2rem;
      margin-right: 2rem;

      &-right {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-button {
          margin-right: 1rem;
          display: block;
          /* display: none;
          @media only screen and (max-width: 600px) {
            display: block;
          } */
        }
        &-title {
          margin-top: 0.3rem;
          h3 {
            font-size: 1.6rem;
            font-family: var(--main-font-primary);
            color: var(--main-text-color);
            font-weight: 400 !important;
            margin-bottom: 0rem;
            line-height: 1.6rem;
          }

          span {
            color: var(--sub-text-color);
            font-size: 1.3rem;
            line-height: 1.3rem;
            font-family: var(--main-font-secondary) !important;
            margin-bottom: 0rem;
            font-weight: 400 !important;
          }
        }
      }

      &-buttons {
        display: flex;
        margin-left: 1rem;

        &-backBtn {
          display: none;
          /* display: block;
          @media only screen and (max-width: 600px) {
            display: none;
          } */
        }

        @media only screen and (max-width: 600px) {
          // flex-direction: column;
          align-items: flex-end;
        }

        & button:first-child {
          margin-right: 1rem;

          @media only screen and (max-width: 600px) {
            margin-bottom: 0.5rem;
            margin-right: 0rem;
          }
        }
      }
    }

    &--bottom {
      transition: $normal-transition;
      width: 100%;
      padding: 2rem;
      display: flex;
      /* align-items: center; */
      justify-content: center;
      flex-direction: column;
    }
  }
}
