@import '../abstracts/variables.sass';

.headerStoreButton {
  padding: 0.9rem 1.5rem;
  font-size: 1.4rem;
  font-family: var(--main-font-primary);
  transition: $normal-transition;
  background-color: var(--store-btn-color);
  color: var(--main-text-color);
  border: $input-border-1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.7rem;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-header-store-button-normal='true'] {
    border-radius: 0.7rem;
    border-color: transparent;
  }

  &:hover {
    background-color: var(--gray-btn-light-background-color);
  }

  svg {
    transition: $normal-transition;
    height: 1.4rem;
    margin-right: 0.3rem;
    fill: var(--main-text-color);
  }
  p {
    margin-bottom: 0;
    font-family: var(--main-font-secondary) !important;
  }
  &[data-header-store-button-normal='true']:hover {
    background-color: $dropdown-hover-color;
    color: var(--main-brand-hover-color);
    svg {
      fill: $main-light-gray-font-color-2;
    }
  }
}

.headerStoreButtonWithoutBorder {
  padding: 0.5rem 1.5rem;
  font-size: 1.4rem;
  font-family: var(--main-font-primary);
  transition: $normal-transition;
  background-color: var(--store-btn-color);
  color: var(--main-text-color);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: $normal-transition;
    height: 1.4rem;
    margin-right: 0.3rem;
    fill: var(--main-text-color);
  }
  p {
    margin-bottom: 0;
    font-family: var(--main-font-secondary) !important;
  }
}
