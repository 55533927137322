@import '../../abstracts/variables.sass';

.progressBar {
  height: 0.7rem;
  width: 100%;
  border-radius: 9999px;
  overflow: hidden;
  border: none;
  // background-color: rgb(236, 236, 236);
  background-color: var(--subPageLinkHolder-link-hover-color);
  position: relative;

  &-level {
    height: 100%;
    background-color: var(--main-brand-color);
    position: absolute;
    z-index: 1;
    border-radius: 9999px;
    transition: all 0.3s ease;
  }
}
