@import '../../abstracts/variables.sass';

.shopPage {
  &__divider {
    width: 100%;
    height: 0.1rem;
    background-color: var(--containers-border-color);
  }
  &__container1 {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: center;
    margin-bottom: 1rem;

    h1 {
      color: var(--main-text-color);
    }
    /* @media only screen and (max-width: 550px) {
      justify-content: center;
    } */

    &--link {
      color: $main-light-gray-font-color-5;
      font-size: 1.5rem;
      font-weight: 400 !important;
      cursor: pointer;
      transition: $normal-transition;
      &:hover {
        color: var(--main-brand-color);
      }
    }
  }

  &__title {
    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-family: var(--main-font-primary);
      color: var(--main-text-color);
      font-weight: 500 !important;
      line-height: 1.5rem;
      margin-bottom: 1rem;
      // margin-left: 1rem;
    }
    hr {
      width: 100%;
      height: 0.1rem;
      border-bottom: 0.05rem solid var(--containers-border-color);
      background-color: transparent;
      margin-bottom: 1rem;
      // margin-left: 1rem;
    }
  }

  &__container1-alignLeft {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: flex-start;
    margin-bottom: 1rem;
    gap: 1rem;

    h1 {
      color: var(--main-text-color);
    }

    .selectableButton {
      margin: 0rem;
    }

    &--link {
      color: $main-light-gray-font-color-5;
      font-size: 1.5rem;
      font-weight: 400 !important;
      cursor: pointer;
      transition: $normal-transition;
      &:hover {
        color: var(--main-brand-color);
      }
    }
  }

  &__container2 {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    margin-bottom: 1rem;

    /* @media only screen and (max-width: 550px) {
      justify-content: center;
    } */
  }
}
