@import '../../abstracts/variables.sass';

.commonDropdown {
  position: absolute;
  background-color: var(--sidebar-color);
  border-radius: 1rem;
  min-width: 20rem;
  overflow: hidden;
  box-shadow: $normal-box-shadow-plus;
  top: $header-height;
  padding: 1.5rem 0 1.5rem 1.7rem;
  display: flex;
  flex-direction: column;
  // left: 15rem;
  left: 12rem;

  span {
    border-bottom: $input-border-small;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--main-text-color);
    padding-right: 3rem;
    padding: 0.5rem;
    transition: $normal-transition;

    &:hover {
      color: var(--sub-text-color);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
