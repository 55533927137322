@import '../../../abstracts/variables.sass';

.taraChat {
  background-color: var(--sidebar-background-color);
  // background-color: rgb(214, 214, 214);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 12rem;
  align-items: center;

  &__content {
    width: 75rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    @media only screen and (max-width: 1080px) {
      width: 80%;
    }
  }

  overflow-y: auto;
  -ms-overflow-style: none; //Hide scrollbar for IE and Edge
  scrollbar-width: none; //Hide scrollbar for Firefox

  //Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // /* Scrollbar Styling */
  // &::-webkit-scrollbar {
  //   width: 0.8rem; /* Width of the scrollbar */
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: var(
  //     --subPageLinkHolder-link-hover-color
  //   ); /* Track color */
  //   border-radius: 0.5rem;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: gray; /* Scrollbar thumb color */
  //   border-radius: 0.5rem;
  //   border: 0.2rem solid gray; /* Optional border for thumb */
  //   transition: $normal-transition;
  // }

  // &::-webkit-scrollbar-thumb:hover {
  //   background-color: gray; /* Thumb color on hover */
  // }

  // &::-webkit-scrollbar-button {
  //   background-color: transparent;
  // }
}
