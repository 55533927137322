@import '../../abstracts/variables.sass';

.subPageList {
  // width: 80%;
  width: 100%;

  h1,
  h3,
  p,
  span {
    margin-bottom: 0;
  }

  &__top {
    margin-bottom: 2rem;
    h1 {
      color: var(--main-text-color);
      font-weight: 600;
      font-size: 1.5rem;
      font-family: var(--main-font-primary);
    }
    span {
      color: $main-light-gray-font-color-3;
      font-size: 1.4rem;
    }
  }

  &__bottom {
    &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 7rem;
      background-color: var(--containers-background-color);
      margin-bottom: 1rem;
      border-bottom: $input-border-small;

      &-left {
        display: flex;
        align-items: center;
        width: 80%;
        &-img {
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          border: $input-border-small;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        &-titles {
          overflow: hidden;
          h3 {
            color: var(--main-text-color);
            font-weight: 600 !important;
            font-size: 1.4rem;
            line-height: 1.6rem;
            margin-bottom: 0.3rem;
            font-family: var(--main-font-primary);
          }
          p {
            color: $main-light-gray-font-color-3;
            font-size: 1.4rem;
            line-height: 1.6rem;
            font-family: var(--main-font-secondary) !important;
          }
          span {
            color: var(--main-brand-color);
            font-size: 1.4rem;
            line-height: 1.4rem;
            font-weight: 500;
          }
        }
      }

      &-right {
        &-svg {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          background-color: var(--containers-background-color);
          transition: $normal-transition;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;

          &:hover {
            background-color: var(--subPageList-hover-color);
          }

          svg {
            height: 2rem;
            width: 2rem;
            fill: $main-light-gray-font-color-3;
          }
        }
      }
    }
  }
}
