@import '../../abstracts/variables.sass';

.pieChartComponent {
  margin-top: 2rem;
  // width: auto;
  width: 100%;
  padding: 2rem;
  border-radius: 0.8rem;
  background-color: var(--sidebar-background-color);
  border: 1px solid var(--main-border-color);
  -webkit-box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  height: 40rem;
  overflow-y: auto;
  -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
  scrollbar-width: none; /*Hide scrollbar for Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
    height: auto;
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--main-text-color);
    margin-bottom: 0;
    &--sub {
      margin: 0 !important;
      font-size: 1.5rem;
      font-weight: 400;
      color: $main-dark-gray-font-color;
    }
  }
  &__chart {
    margin-top: 1rem;
    &--container {
      &-chart {
        // height: 250px;
      }

      &-legend {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
