@import '../abstracts/variables.sass';

.textAreaAI {
  position: relative;
  overflow: hidden;
  border: $input-border-1;
  border-radius: 0.8rem;
  height: 12rem;
  margin-top: 1.6rem;
  width: 100%;
  transition: $normal-transition;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  background-color: var(--input-background-color);
  flex-flow: column-reverse wrap;

  &:hover {
    border: $input-border-2;
  }

  &:focus-within {
    -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

    @media only screen and (max-width: 850px) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    label {
      color: $main-light-gray-font-color-3;
      font-family: var(--main-font-primary) !important;
    }
  }

  /* label {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--main-text-color);
    padding-left: 1rem;
    top: 0.5rem;
    transition: $normal-transition;
  } */

  textarea,
  textarea:active,
  textarea:focus {
    outline: none;
    background-color: var(--input-background-color);
    padding-left: 1rem;
    color: var(--main-text-color);
  }

  textarea {
    width: 99%;
    margin-bottom: 1rem;
    padding-top: 3.5rem;
    height: 12rem !important;
  }

  textarea:invalid[data-focused='true'] ~ label {
    color: $web-color-danger !important;
    font-family: var(--main-font-primary) !important;
  }

  textarea:focus + .loginInput > .loginInput__left > svg {
    fill: $main-light-gray-font-color-3;
  }

  &__labelContainer {
    position: absolute;
    padding-left: 1rem;
    top: 0rem;
    transition: $normal-transition;
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    background-color: var(--input-background-color);
    label {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
    }

    div {
      font-size: 1.1rem;
      font-weight: 500;
      color: #fff;
      margin-left: 1rem;
      padding: 0.5rem 1rem;
      background-color: $web-color-success;
      cursor: pointer;
      border-radius: 0.5rem;
    }
  }
  &-error {
    background-color: $web-color-danger !important;
  }
  &-warning {
    background-color: $web-color-orange !important;
  }
}

.textAreaAI-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  height: 1rem;
  margin-left: 0.2rem;
}
