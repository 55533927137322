@import '../../../abstracts/variables.sass';

.taraChatItemUser {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  &__message {
    background-color: var(--subPageLinkHolder-link-hover-color);
    color: var(--tara-message-color);
    border-radius: 0.8rem;
    padding: 1rem;
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-line; // This allows the line breaks to be shown
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 1rem;
    &--normal {
      font-family: var(--main-font-primary) !important;
      margin-top: -0.7rem;
      cursor: pointer;
      background-color: var(--main-btn-color);
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      border: 0.5px solid var(--main-btn-color);
      justify-content: center;
      transition: $normal-transition;
      color: var(--main-btn-text-color);
      font-size: 1.3rem;
      font-weight: 400;
      &:hover {
        opacity: 0.8;
      }
    }

    &--yes {
    }
    &--no {
      background-color: var(--sidebar-background-color);
      border: 0.5px solid var(--main-btn-color);
      color: var(--main-btn-color);
    }
  }

  &__icon {
    background-color: var(--gray-btn-light-background-color);
    margin-left: 1rem;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
  }
}
