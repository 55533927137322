@import '../abstracts/variables.sass';

.simpleCard {
  background-color: var(--containers-background-color);
  border: 1px solid var(--main-border-color);
  -webkit-box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  /* -webkit-box-shadow: $normal-box-shadow-plus;
  box-shadow: $normal-box-shadow-plus; */
  padding: 2rem;
  border-radius: 0.8rem;
  margin: 2rem;
  flex-grow: 1;
  width: 80%;
  @media only screen and (max-width: 1000px) {
    width: 95%;
  }
}
