@import '../abstracts/variables.sass';

.commonModal {
  /* background-color: $main-white-color;
  border-radius: $normal-border-radius; */
  width: 100%;
  &__title {
    width: 100%;
    border-bottom: $input-border-small;
    h1 {
      font-size: 1.8rem;
      color: var(--main-text-color);
      font-weight: 600;
      line-height: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__content {
    padding: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
      width: 6rem;
      height: 6rem;
      fill: $web-color-danger;
      margin-bottom: 1rem;
    }
    h1 {
      font-size: 2.2rem;
      color: var(--main-text-color);
      margin-bottom: 1rem;
      font-weight: 800;
      text-align: center;
    }
    h3 {
      font-size: 1.5rem;
      color: var(--main-text-color);
      margin-bottom: 1rem;
      font-weight: 400;
      text-align: center;
    }
    h4 {
      font-size: 1.4rem;
      color: $web-color-danger;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 800;
      text-align: center;
      font-style: italic;
    }
    input {
      width: 25rem;
    }
    &--buttons {
      margin-top: 1rem;
      display: flex;
      button {
        margin-right: 1rem;
        margin-left: 1rem;
      }
    }
    &--delete__button {
      height: 3.8rem;
      margin-top: 1rem;
      display: flex;
    }
  }
}
