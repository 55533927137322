@import '../../abstracts/variables.sass';

.productsPage {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &--left {
      display: flex;
      flex-wrap: wrap;
      margin-right: 3rem;
    }

    &--right {
      /* padding-right: 1.5rem; */
    }
  }

  &__bottom {
    /* margin-top: 2rem; */
    width: 100%;
    /* margin-bottom: 2rem; */

    &--dataGridContainer {
      /* height: 87rem; */
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      /*----------DISABLE MUI PAGINATION STYLES START ----------- */
      .css-17jjc08-MuiDataGrid-footerContainer,
      .MuiDataGrid-footerContainer {
        display: none !important;
      }
      /*----------DISABLE MUI PAGINATION STYLES END ------------- */
    }
  }
}
