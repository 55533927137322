@import '../../../abstracts/variables.sass';
.taraBasicButton {
  &[data-tara-basic-button-use='yellow'] {
    background-color: #ffdd33dc;
    color: rgb(22, 22, 22);
    border: 0.5px solid #ffdd33;
    svg {
      fill: rgb(22, 22, 22);
    }

    p {
      font-weight: 500;
    }

    &:hover {
      background-color: #ffdd33;
    }
  }

  &[data-tara-basic-button-use='normal'] {
    color: var(--main-text-color);
    background-color: var(--sidebar-background-color);
    border: $input-border-small;

    svg {
      fill: var(--main-text-color);
    }

    p {
      font-weight: 500;
    }

    &:hover {
      background-color: var(--subPageLinkHolder-link-hover-color);
    }
  }

  padding: 0.8rem 1.8rem;
  font-size: 1.4rem;
  font-family: var(--main-font-primary);
  transition: $normal-transition;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3rem;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: $normal-transition;
    height: 1.4rem;
    margin-right: 0.3rem;
  }

  p {
    margin-bottom: 0;
    font-family: var(--main-font-secondary) !important;
  }
}
