@import '../../abstracts/variables.sass';

.dashboardTab--container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // padding: 2rem;
  margin-top: 2rem;
}
.dashboardTab--titles {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // padding: 2rem;
  padding-bottom: 2rem;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2rem !important;
    color: var(--main-text-color);
    margin: 0 !important;
    font-family: var(--main-font-primary) !important;
    margin-bottom: 1rem !important;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.6rem !important;
    color: var(--main-text-color);
    margin: 0 !important;
    font-family: var(--main-font-primary) !important;
    margin-bottom: 1rem !important;
  }

  &-steps {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    span {
      font-size: 1.3rem;
      font-weight: 500;
      color: $main-dark-gray-font-color;
    }
  }
}

.dashboardTab {
  background-color: var(--sidebar-background-color);
  border-radius: 2rem;
  padding: 0rem 2rem;
  margin-bottom: 2rem;
  width: 80%;
  border: 1px solid var(--main-border-color);
  box-shadow: 0px 4px 20px 0px #0000000d;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  @media only screen and (max-width: 750px) {
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 0;
    background-color: transparent;
  }

  &__item {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
    @media only screen and (max-width: 750px) {
      background-color: var(--sidebar-background-color);
      padding: 1rem;
      border: 1px solid var(--main-border-color);
      border-radius: 1rem;
      box-shadow: 0px 4px 20px 0px #0000000d;

      button {
        background-color: var(--sidebar-background-color);
      }
      &:hover {
        background-color: var(--gray-btn-light-background-color);

        button {
          background-color: var(--gray-btn-light-background-color);
        }
      }
    }

    &--mobile-click-overlay {
      display: none;
      @media only screen and (max-width: 750px) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 1rem;
      }
    }

    &--left {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2rem;

      &-tickBox {
        width: 2rem;
        height: 2rem;
        border: 1px solid var(--main-border-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 1rem;
        flex-shrink: 0;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          flex-shrink: 0;
          fill: transparent;
        }

        &[data-is-dashboard-item-done='true'] {
          border: 1px solid $web-color-success;
          svg {
            fill: $web-color-success;
          }
        }
      }

      &-icon {
        width: 5rem !important; // Added !important
        height: 5rem !important; // Added !important
        border: 1px solid var(--main-border-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        margin-right: 2rem;
        flex-shrink: 0; // Prevent shrinking

        &[data-fill-dash-svg='true'] {
          svg {
            width: 3rem !important; // Added !important
            height: 3rem !important; // Added !important
            fill: #666666;
          }
        }

        svg {
          width: 3rem !important; // Added !important
          height: 3rem !important; // Added !important
          flex-shrink: 0; // Prevent shrinking
        }
      }

      /* &-titleContainer {
        display: flex;
        align-items: center;
      } */

      &-title {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--main-text-color);
      }
      &-subtitle {
        font-size: 1.2rem;
        font-weight: 500;
        color: $main-dark-gray-font-color;
      }
    }

    &--right {
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 5rem;

      &-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1.5rem;
        background-color: #323333;
        height: 3.8rem;
        border-radius: 0.8rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.3s ease-in-out;

        @media only screen and (max-width: 750px) {
          padding: 1rem;
          background-color: var(--containers-background-color);
          height: 3.8rem;
          border-radius: none;
        }

        &:disabled {
          cursor: not-allowed;
        }

        span {
          font-size: 1.6rem;
          font-weight: 600;
          color: #fff;
          margin-bottom: 0.17rem !important;
          margin-right: 1rem;

          @media only screen and (max-width: 750px) {
            display: none;
          }
        }

        svg {
          width: 1.8rem;
          height: 1.8rem;
          fill: #fff;
          flex-shrink: 0; // Prevent shrinking

          @media only screen and (max-width: 750px) {
            fill: #323333;
          }
        }

        img {
          width: 1.8rem;
          height: 1.8rem;
          object-fit: contain;
          flex-shrink: 0; // Prevent shrinking
        }

        &[data-dash-success-svg='true'] {
          svg {
            margin-left: 0rem;
          }
        }
        &[data-is-dashboard-item-done='true'] {
          padding: 0.5rem 1rem;
          svg {
            width: 2.5rem;
            height: 2.5rem;
            margin-left: 0rem;
          }
        }
      }
    }
  }
}
