@import '../../abstracts/variables.sass';

.pluginAuthPage {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 750px) {
      width: 95%;
    }

    &--top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2rem;
      img {
        height: 15rem;
        margin-bottom: 1rem;
      }

      h1 {
        font-size: 2rem;
        font-weight: 600;
        color: var(--main-text-color);
      }
    }

    &--center {
      width: 95%;
      //   box-shadow: $normal-box-shadow-plus;
      -webkit-box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 0.91);
      box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 0.91);
      padding: 2rem;
      border-radius: $normal-border-radius;
      background-color: var(--header-color);
      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        &-icon {
          margin-right: 2rem;
          svg {
            height: 2.5rem;
            width: 2.5rem;
            fill: gray;
          }
        }
        &-text {
          flex-grow: 1;
          h2 {
            margin-bottom: 0;
            color: var(--main-text-color);
            font-size: 1.4rem;
            font-weight: 600;
          }
          h3 {
            margin-bottom: 0;
            color: $main-light-gray-font-color-3;
            font-size: 1.2rem;
            font-weight: 400;
          }
        }
      }

      &-divider {
        border-bottom: $input-border-small;
      }

      &-btn {
        background-color: #28a745;
        border-radius: $button-border-radius;
        text-align: center;
        padding: 1rem 2rem;
        cursor: pointer;
        margin-top: 2rem;
        margin-bottom: 1rem;
        h2 {
          font-size: 1.5rem;
          margin-bottom: 0;
          color: #ffffff;
        }
      }
      &-info {
        text-align: center;
        h2 {
          margin-bottom: 0;
          color: $main-light-gray-font-color-3;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.2rem;
        }

        a {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }

    &--bottom {
      margin-top: 2rem;
      color: $main-light-gray-font-color-3;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.2rem;
      cursor: pointer;
    }
  }
}
