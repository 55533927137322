@import '../../abstracts/variables.sass';

.addProductAttributes {
  border-top: $input-border-small;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;

  &__top {
    display: flex;
    align-items: center;
    height: 5rem;
    /* justify-content: space-between; */
    &--title {
      height: 2.5rem;
      h1 {
        margin: 0;
        font-size: 1.9rem;
        color: var(--main-text-color);
        font-weight: 600;
        line-height: 2rem;
        margin-right: 2rem;
      }
    }
  }

  &__bottom {
    margin-top: 1rem;
    width: 100%;
    &--item {
      padding: 0.5rem 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      border-radius: $normal-border-radius;
      -webkit-box-shadow: 1px 11px 8px -15px rgba(0, 0, 0, 0.47);
      box-shadow: 1px 11px 8px -15px rgba(0, 0, 0, 0.47);
      // background-color: $main-light-gray-font-color-8;
      @media only screen and (max-width: 750px) {
        justify-content: flex-start;
      }

      &-inputs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media only screen and (max-width: 750px) {
          flex-direction: column;
        }
        &-name {
          width: 50%;
          margin-right: 1rem;
          @media only screen and (max-width: 750px) {
            margin-right: 2rem;
            width: 90%;
          }
        }
        &-value {
          width: 50%;
          margin-right: 2rem;
          margin-left: 1rem;
          @media only screen and (max-width: 750px) {
            margin-left: 0;
            width: 90%;
          }
        }
      }

      &-btn {
        width: 4rem;
        margin-right: 1rem;
      }
    }
  }
}
