@import '../../abstracts/variables.sass';

.productDetailPage {
  display: flex;
  flex-direction: column;
  &-backButton {
    align-self: flex-end;
    button > svg {
      fill: var(--icon-color);
      height: 1.7rem;
    }
  }
  &__top {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      /* align-items: center; */
    }
    &--left {
      min-width: 30rem;
      max-width: 25rem;
      height: 25rem;
      overflow: hidden;
      /* border: 1px solid var(--main-brand-color); */
      margin-right: 4rem;
      border-radius: $normal-border-radius;
      box-shadow: $normal-box-shadow-plus;
      @media only screen and (max-width: 1000px) {
        margin-right: 0rem;
        margin-bottom: 2rem;
        max-width: 100%;
        /* height: 30rem; */
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      video {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &--center,
    &--right {
      width: 30%;
      margin-left: 2rem;
      @media only screen and (max-width: 1000px) {
        /* padding: 2rem; */
        margin-left: 0rem;
        width: 100%;
      }

      h1 {
        font-size: 1.9rem;
        font-weight: 600;
        color: var(--main-text-color);
      }

      h2 {
        font-size: 1.4rem;
        color: var(--main-text-color);
        font-weight: 600;
        white-space: pre-wrap;
        span {
          color: var(--main-text-color);
          font-weight: 400;
        }
      }
    }
    &--center {
      &-status {
        height: 2rem;
        display: flex;
        align-items: center;

        span {
          margin-left: 0.5rem;
        }
        div {
          margin-left: 0.5rem;
          height: 1.4rem;
          width: 1.4rem;
          border-radius: 50%;
        }

        div[data-single-product-status='0'] {
          background-color: $web-color-danger;
        }

        div[data-single-product-status='1'] {
          background-color: $web-color-success;
        }
      }
    }
    &--right {
    }
  }

  &--variants-text {
    /* text-align: center; */
    font-size: 1.5rem;
    color: var(--main-text-color);
    font-weight: 500;
    margin-top: 4rem;
    border-top-left-radius: $normal-border-radius;
    border-top-right-radius: $normal-border-radius;
    border-top: $input-border-small;
    border-left: $input-border-small;
    border-right: $input-border-small;
    padding: 1rem;
  }

  &__bottom {
    /* overflow-x: scroll; */
  }
}
