@import '../../abstracts/variables.sass';

.quickOnboarding {
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;

  &__account {
    &[data-is-quickOnboarding-profile-open='true'] {
      .quickOnboarding__account--content {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }
      .quickOnboarding__account--content-right svg {
        transform: rotate(-90deg);
      }
    }

    &--content {
      position: fixed;
      height: 4rem;
      z-index: 100;
      top: 2rem;
      right: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2.5rem;
      // border: 1px solid var(--main-border-color);
      border-radius: 0.8rem;
      transition: $normal-transition;
      cursor: pointer;

      &:hover {
        background-color: var(--subPageLinkHolder-link-hover-color);
      }
      &-left {
        color: var(--main-text-color);
        font-size: 1.6rem;
        font-weight: 500;
        margin-right: 1rem;
      }
      &-right {
        svg {
          cursor: pointer;
          transition: $normal-transition;
          fill: var(--main-text-color);
          height: 1rem;
          width: 1rem;
          transform: rotate(90deg);
          &:hover {
          }
        }
      }

      .sidebarProfileTheme2 {
        z-index: 101 !important;
        top: 4.5rem !important;
        right: 0rem !important;
        left: unset !important;
        bottom: unset !important;
      }
    }
  }

  &__logo {
    position: fixed;
    top: 3rem;
    height: 5rem;
    display: none;
    img {
      height: 100%;
    }

    @media only screen and (max-width: 850px) {
      display: block;
    }
  }

  &__content {
    background-color: var(--sidebar-background-color);
    // padding: 2rem;
    // padding-top: 1rem;
    min-height: 100vh;
    width: 100%;

    overflow-y: auto;

    -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* @media only screen and (max-width: 950px) {
      width: 40rem;
    } */

    @media only screen and (max-width: 850px) {
      border-radius: 0.8rem;
      //   border: 1px solid $main-light-gray-font-color-2;
      //   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      background-color: var(--sidebar-background-color);
      //   padding: 2rem 0;
    }
    @media only screen and (max-width: 450px) {
      width: 90%;
    }
  }

  &__right {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: var(--sidebar-background-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 850px) {
      width: 100%;
      //   padding: 2rem 0;
      //   background-color: var(--main-background-color);
      height: 100%;
    }

    &--top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-top: 5rem;
      margin-bottom: 4rem;

      @media only screen and (max-width: 850px) {
        margin-top: 2rem;
        margin-bottom: 2rem;
        align-items: center;
      }

      h1 {
        font-size: 2.5rem;
        font-weight: 500;
        color: var(--main-text-color);

        font-family: var(--main-font-primary) !important;
      }

      p {
        max-width: 90%;
        font-size: 1.5rem;
        font-weight: 400;
        color: #71717a;
        line-height: 2rem;
        font-family: var(--main-font-secondary) !important;
      }
    }

    &--center {
      &-links {
        margin-top: 1rem;
        font-size: 1.3rem;
        font-weight: 400;
        color: var(--main-text-color);
        line-height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-forgotPassword {
          cursor: pointer;
          color: $main-light-gray-font-color-4;
          transition: $normal-transition;
          font-weight: 600;
          margin-left: 0.5rem;
          margin-right: 0.5rem;

          &:hover {
            color: var(--main-brand-color);
          }
        }
      }
    }

    &--bottom {
      width: 100%;
      margin-top: 2rem;

      &-button {
        width: 100%;
        margin-top: 1rem;
        .mainSquareButton {
          width: 100%;
          height: 4rem;
          font-size: 1.5rem;
          transition: $normal-transition;
          background-color: rgb(22, 22, 22);
          &:hover {
            background-color: rgb(36, 36, 36);
          }

          &:disabled {
            background-color: rgb(22, 22, 22);
            cursor: not-allowed;

            &:hover {
              background-color: rgb(36, 36, 36);
            }
          }
        }
      }

      &-links {
        margin-top: 1em;
        width: 100%;
        text-align: center;

        &-privacy {
          margin-bottom: 0rem;
          color: var(--main-text-color);
          font-weight: 400;
          font-size: 1.3rem;

          span {
            cursor: pointer;
            color: $web-color-primary;
            margin-left: 0.1rem;
            margin-right: 0.1rem;
            font-weight: 500;
          }
        }

        h3 {
          margin-bottom: 0rem;
          color: var(--main-text-color);
          font-weight: 400;
          font-size: 1.3rem;
        }

        h2 {
          margin-bottom: 0rem;
          color: var(--main-text-color);
          font-weight: 400;
          font-size: 1.3rem;
          span {
            cursor: pointer;
            color: $web-color-primary;
            margin-left: 0.1rem;
            margin-right: 0.1rem;
            font-weight: 500;
          }
        }
      }

      &-privacy {
        margin-top: 0.2rem;
        color: var(--main-text-color);
        font-weight: 400;
        font-size: 1.3rem;
        text-align: center;

        span {
          cursor: pointer;
          transition: $normal-transition;
          color: var(--main-text-color);
          margin-left: 0.1rem;
          margin-right: 0.1rem;
          font-weight: 500;

          &:hover {
            color: $web-color-primary;
          }
        }
      }
    }

    &--other {
      width: 100%;
      margin-top: 2rem;
      border-top: $input-border-small;
      display: flex;

      img {
        flex-grow: 1;
        margin-top: 2rem;
        object-fit: contain;
        height: 4rem;
        border: $input-border-small;
        cursor: pointer;
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    @media only screen and (max-width: 850px) {
      display: none;
    }

    &--content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        font-size: 2.5rem;
        font-weight: 500;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0;
      }
    }

    &--image {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        object-fit: contain;
        width: 55%;
      }

      &-lottie {
        width: 40%;
        object-fit: contain;
      }
    }
  }
}
