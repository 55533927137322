@import '../../abstracts/variables.sass';

.customEvent {
  background-color: var(--sidebar-background-color);
  border: 1px solid var(--main-border-color);
  padding: 2rem !important;
  padding-top: 0 !important;
  border-radius: 0.8rem;

  .aiTagInput {
    width: 100% !important;
    height: auto !important;
    min-height: 4.5rem !important;
  }

  &-title {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary);
  }

  &__radioInputs {
    margin-top: 2rem;
    &--label {
      margin-bottom: 0.8rem;
      font-size: 1.4rem;
      font-weight: 600 !important;
      color: var(--main-text-color);
      transition: $normal-transition;
      font-family: var(--main-font-primary) !important;
    }

    &--radioItems {
      display: flex;
      align-items: center;
      gap: 2rem;

      &-radio {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        label {
          font-size: 1.4rem;
          font-weight: 500 !important;
          color: var(--main-text-color);
          transition: $normal-transition;
          font-family: var(--main-font-primary) !important;
        }

        &-input {
          cursor: pointer;
          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }
  }

  &__table {
    margin-top: 2rem;

    .MuiTableContainer-root {
      background-color: var(--sidebar-background-color) !important;
      border: 1px solid var(--main-border-color) !important;
      border-radius: 0.8rem !important;
      padding: 2rem !important;
      box-shadow: none !important;
    }

    .MuiTableCell-body,
    .MuiTableCell {
      font-size: 1.3rem !important;
      color: var(--main-text-color) !important;
      transition: $normal-transition !important;
      font-family: var(--main-font-primary) !important;
      padding: 1rem !important;
    }

    .MuiTableCell-head {
      font-size: 1.3rem !important;
      color: $main-dark-gray-font-color !important;
      transition: $normal-transition !important;
      font-family: var(--main-font-primary) !important;
      padding: 1rem !important;
    }

    .MuiTableRow-root {
      background-color: var(--sidebar-background-color) !important;
      transition: $normal-transition !important;
      &:hover {
        background-color: var(--subPageLinkHolder-link-hover-color) !important;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 0.5rem;
  }

  &__editButton,
  &__deleteButton {
    transition: $normal-transition;
    border: 1px solid var(--main-border-color);
    height: 3.5rem;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__editButton {
    background-color: var(--sidebar-background-color);

    &:hover {
      background-color: var(--subPageLinkHolder-link-hover-color);
    }
    svg {
      fill: var(--main-text-color);
    }
  }

  &__deleteButton {
    background-color: #fee2e2;
    &:hover {
      background-color: #fad9d9;
    }
    svg {
      fill: #dc2626;
    }
  }
}
