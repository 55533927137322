@import '../../abstracts/variables.sass';

.settingsShippingAdd {
  width: 100%;
  padding-bottom: 2rem;

  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // padding-left: 1rem;
    h1 {
      margin: 0;
      font-size: 1.5rem;
      color: var(--main-text-color);
      font-weight: 400;
      line-height: 2rem;
    }
    /* button {
      background-color: $main-light-gray-bg-color;
    } */
  }

  &__bottom {
    margin-top: 1rem;
    width: 100%;
    &--item {
      padding: 0.5rem 0rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      border-radius: $normal-border-radius;
      -webkit-box-shadow: 1px 11px 8px -15px rgba(0, 0, 0, 0.47);
      box-shadow: 1px 11px 8px -15px rgba(0, 0, 0, 0.47);
      // background-color: $main-light-gray-font-color-8;
      @media only screen and (max-width: 750px) {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
      }

      &-inputs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media only screen and (max-width: 750px) {
          flex-direction: column;
        }
        &-country {
          width: 50%;
          margin-right: 1rem;
          @media only screen and (max-width: 750px) {
            margin-right: 2rem;
            width: 90%;
          }
        }
        &-cost {
          width: 50%;
          margin-right: 2rem;
          margin-left: 1rem;
          @media only screen and (max-width: 750px) {
            margin-left: 0;
            width: 90%;
          }
        }
      }

      &-btn-container {
        display: flex;
        width: 9rem;

        @media only screen and (max-width: 750px) {
          gap: 1rem;
          margin-left: 3%;
        }
      }
      &-btn {
        width: 4rem;
        margin-right: 1rem;
      }
    }
  }
}
