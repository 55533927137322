@import '../../abstracts/variables.sass';

.profileDropdown {
  position: absolute;
  /* left: 110%;
  bottom: 2rem; */
  background-color: var(--sidebar-color);
  border-radius: 0.8rem;
  width: 25rem;
  padding-bottom: 1.5rem;
  overflow: hidden;
  right: 2rem;
  top: $header-height;

  & hr {
    height: 1px;
    background-color: $main-light-gray-font-color-3 !important;
  }

  box-shadow: $normal-box-shadow-plus;

  /* @media only screen and (max-width: 750px) {
     right: 5%;
    top: $header-height;
  } */
  /* @media only screen and (min-width: 750px) {
    right: 10%;
    top: $header-height;
  } */

  &__top {
    width: 100%;
    min-height: 10rem;
    background-color: var(--sidebar-color);
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &--pic {
      height: 6.8rem;
      width: 6.8rem;
      min-height: 6.8rem;
      min-width: 6.8rem;
      overflow: hidden;
      border-radius: 50%;
      border: $input-border-1;

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    &--text {
      max-width: 70%;
      word-wrap: break-word;
      margin-left: 1rem;
      p {
        margin-bottom: 0;
        font-size: 1.5rem;
        color: var(--main-text-color);
        line-height: 1.7rem;
        font-weight: 800;
        font-family: var(--main-font-primary) !important;
      }

      span {
        font-size: 0.9rem;
        color: $main-light-gray-font-color-3;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  &__center {
    max-height: 20rem;
    overflow-y: scroll;
    -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &--item {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      cursor: pointer;
      transition: $normal-transition;
      // border-bottom: 1px solid $main-light-gray-font-color-3;

      &:hover {
        background-color: var(--gray-btn-light-background-color);
      }

      &-mode[data-profile-mode='true'] {
        svg {
          fill: #ffb62e;
        }
      }
      &-mode[data-profile-mode='false'] {
        svg {
          fill: #444444;
        }
      }

      &-mode[data-profile-settings-mode='true'] {
        svg {
          fill: #eaeaea;
        }
      }
      &-mode[data-profile-settings-mode='false'] {
        svg {
          fill: #444444;
        }
      }

      &-left {
        display: flex;
        align-items: center;
        svg {
          height: 2rem;
          width: 2rem;
          fill: $main-light-gray-font-color-3;
        }
      }

      &-right {
        margin-left: 2rem;
        p {
          margin-bottom: 0;
          font-size: 1.3rem;
          color: var(--main-text-color);
          line-height: 1.3rem;
          font-weight: 500;
          font-family: var(--main-font-secondary) !important;

          span {
            color: var(--main-text-color);
            font-weight: 600;
            margin-right: 3px;
          }
        }

        span {
          font-size: 1.2rem;
          color: $main-light-gray-font-color-3;
        }
      }
    }
  }

  &__bottom {
    padding: 1rem;
    padding-bottom: 0;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--main-btn-color);
      width: 100%;
      padding: 1rem;
      border-radius: 0.7rem;
      transition: $normal-transition;

      svg {
        height: 1.5rem;
      }

      span {
        color: $main-white-color;
        font-size: 1.3rem;
        margin-left: 0.2rem;
        font-weight: 600;
      }
    }
  }
  &__switch {
    padding: 1rem;
    padding-bottom: 0;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 1rem;
      border-radius: 0.7rem;
      transition: $normal-transition;

      svg {
        height: 1.5rem;
      }

      span {
        color: $main-white-color;
        font-size: 1.3rem;
        margin-left: 0.2rem;
        font-weight: 600;
      }
    }
  }
}
