@import '../../abstracts/variables.sass';

.sidebarProfileTheme2 {
  box-shadow: $normal-box-shadow-plus;
  position: absolute;
  z-index: 2;
  bottom: 5rem;
  left: 1rem;
  //   right: 2rem;
  width: $theme-2-sidebar-width - 2rem;
  background-color: var(--sidebar-background-color);
  border: 0.5px solid var(--containers-border-color);
  border-radius: 0.5rem;
  overflow: hidden;
  font-weight: 400;
  padding: 0.5rem 0;

  li {
    padding: 1rem 1.5rem;
    transition: $normal-transition;
    display: flex;
    align-items: center;
    &:hover {
      background-color: var(--subPageLinkHolder-link-hover-color);
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
      fill: var(--main-text-color);
      margin-right: 1.5rem;
    }

    span {
      font-family: var(--main-font-primary);
      font-weight: 600 !important;
      //   margin-bottom: 0rem;

      color: var(--main-text-color);
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}
