@import '../abstracts/variables.sass';

.tagInput {
  align-items: center;
  overflow: hidden;
  border: $input-border-1;
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  min-height: 6rem;
  margin-top: 2.5rem;
  transition: $normal-transition;
  background-color: var(--input-background-color);
  flex-wrap: wrap;
  &:hover {
    border: $input-border-2;
  }
  &:focus-within {
    -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
    box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);

    @media only screen and (max-width: 850px) {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  input:focus + .loginInput > .loginInput__left > svg {
    fill: $main-light-gray-font-color-3;
  }

  input {
    border: none;
    width: 100%;
    height: 3.5rem;
    margin-top: 1.3rem;
    padding-right: 2rem;
    background-color: var(--input-background-color);
    color: var(--main-text-color);
    &:focus {
      outline: transparent;
    }
  }

  label {
    padding-top: 0.1rem;
    font-size: 1.1rem;
    font-weight: 500;
    position: absolute;
    color: var(--main-text-color);
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }

  .label-validation {
    color: $web-color-danger !important;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -0.7rem;

    &--item {
      width: auto;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--main-btn-text-color);
      padding: 0 0.8rem;
      font-size: 1.4rem;
      list-style: none;
      border-radius: 0.6rem;
      margin: 0 0.8rem 0.8rem 0;
      background-color: var(--main-brand-color);

      &-title {
        /* margin-top: 3px; */
        color: var(--main-btn-text-color);
        font-weight: 500;
      }
      span {
        color: var(--main-btn-text-color);
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.6rem;
        text-align: center;
        margin-left: 0.8rem;

        cursor: pointer;

        svg {
          fill: var(--main-btn-text-color);
          height: 1.2rem;
          width: 1.2rem;
        }
      }
    }
  }
}

.tagInput-validation-text {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: 500;
  color: $main-light-gray-font-color-3;
  height: 1.8rem;
  margin-left: 0.2rem;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

@media screen and (max-width: 750px) {
  .tagInput-validation-text {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1300px) {
  .tagInput-validation-text {
    max-width: 30rem;
  }
}
