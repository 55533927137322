@import '../../../abstracts/variables.sass';

.taraInput {
  margin-top: 3rem;
  background-color: var(--input-background-color);
  box-shadow: $normal-box-shadow;
  position: relative;
  overflow: hidden;
  border: $input-border-1;
  // border-width: 2px !important;
  border-radius: 1.5rem;
  max-width: 75rem;
  width: 100%;
  height: 11rem;
  min-height: 11rem;
  max-height: 11rem;
  padding: 1rem;
  transition: $normal-transition;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    margin-bottom: 1rem;

    span {
      font-size: 1.2rem;
      font-weight: 400;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
    }
  }

  &[data-is-input-empty='false'] {
    .taraInput__icon {
      display: none;
    }
  }

  textarea,
  textarea:active,
  textarea:focus {
    width: 100%;
    outline: none;
    height: 100% !important;
    background-color: var(--input-background-color);
    font-size: 1.7rem;
    font-weight: 400;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
    resize: none;
    padding-left: 0.5rem;

    overflow-y: auto;
    -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      font-size: 1.7rem;
      font-weight: 400;
      color: var(--input-border-color);
      font-family: var(--main-font-primary) !important;
      padding-left: 2.8rem;
      padding-top: 0.2rem;
    }
  }

  &__icon {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    svg {
      height: 2rem;
      width: 2rem;
      fill: var(--input-background-color);

      stroke: var(--input-border-color) !important;
    }

    span {
      font-size: 1.7rem;
      font-weight: 400;
      color: var(--input-border-color);
      font-family: var(--main-font-primary) !important;
    }
  }

  &__button {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    background-color: var(--main-btn-color);
    height: 4rem;
    width: 4rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $normal-transition;
    &:hover {
      opacity: 0.8;
    }
    svg {
      height: 2rem;
      fill: var(--main-btn-text-color);
    }
  }
}
