@import '../abstracts/variables.sass';

.selectableButton {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  // border: #8b909a;;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
  box-shadow: 0px 0px 15px -3px rgba(72, 95, 199, 0.25);
  background-color: var(--containers-background-color);
  border-radius: $normal-border-radius;
  height: 8rem;
  min-width: 23rem;
  margin: 2rem;
  cursor: pointer;
  transition: $normal-transition;
  padding: 2rem;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  &__isSelected {
    border: $input-border-small;

    svg {
      fill: #8b909a;
    }
  }

  &:hover {
    border-color: #8b909a;
    svg {
      fill: #8b909a;
    }
    h2 {
      color: #8b909a;
    }
    span {
      color: #8b909a;
    }
  }

  &__icon {
    margin-right: 2rem;
    svg {
      width: 3.8rem;
      height: 3.8rem;
      fill: var(--main-text-color);
      transition: $normal-transition;
    }
    &__isSelected {
      margin-right: 2rem;
      svg {
        width: 3.8rem;
        height: 3.8rem;
        fill: #8b909a;
      }
    }
  }

  &__titles {
    h2 {
      margin-bottom: 0;
      color: var(--main-text-color);
      // text-transform: uppercase;
      font-family: var(--main-font-primary);
      font-size: 1.5rem;
      font-weight: 600 !important;
      transition: $normal-transition;
    }
    span {
      color: var(--main-text-color);
      font-size: 1.4rem;
      transition: $normal-transition;
    }
  }
}
