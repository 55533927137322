//background colors
$main-white-color: #fff;
$main-light-gray-bg-color: #f2f2f2;
$dropdown-dark-bg-color: #fafafa;
$dropdown-hover-color: #f3f3f3;
$popup-overlay-color: rgba(0, 0, 0, 0.7);

//font-colors
$main-dark-gray-font-color: #8b909a;
$main-light-gray-font-color-0: #f7f6f6;
$main-light-gray-font-color-1: #f0f0f0;
$main-light-gray-font-color-2: #cecece;
// $main-light-gray-font-color-3: #9999a9;
$main-light-gray-font-color-3: #4c4c53;
$main-light-gray-font-color-4: #5d5d67;
$main-light-gray-font-color-5: #a2a5b9;
$main-light-gray-font-color-6: #efefef;
$main-light-gray-font-color-7: #dcdbdb;
$main-light-gray-font-color-8: #f9f7f7;
$main-dark-font-color: #2e333d;

//border
$input-border-small: 0.5px solid var(--containers-border-color);
$input-border-small-hover: 0.5px solid #a2a5b9;
$input-border-1: 1px solid var(--input-border-color);
$input-border-1-focus: 1px solid var(--input-border-color);
$input-border-2: 1px solid var(--input-border-hover-color);
$white-border: 1px solid var(--white-border-color);

$normal-border-radius: 0.7rem;
$button-border-radius: 0.5rem;

//shadows
$normal-box-shadow: 0px 0px 15px -11px rgba(0, 0, 0, 0.59);
$normal-box-shadow-plus: 0px 3px 10px -6px rgba(0, 0, 0, 0.84);
$even-box-shadow: 0px 1px 8px -3px rgba(0, 0, 0, 0.91);

//transitions
$normal-transition: all 0.3s ease;

//sidebar
$sidebar-width: 8rem;
$sidebar-mobile-width: 6rem;

//header
$header-height: 7.5rem;

//sidebarTheme2
$theme-2-sidebar-width: 27rem;

//standard web colors
$web-color-orange: #ff6700;
$web-color-dark: #343a40;
$web-color-light: #f8f9fa;
$web-color-info: #17a2b8;
$web-color-warning: #ffc107;
$web-color-danger: #dc3545;
$web-color-danger-hover: #ca6e77;
$main-blue-hover: #0452ac;
$web-color-success: #28a745;
$web-color-secondary: #6c757d;
$web-color-primary: #007bff;
$web-color-disabled: #dddddd;
