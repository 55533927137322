@import '../../abstracts/variables.sass';

.shareProduct {
  /* background-color: $main-white-color;
  border-radius: $normal-border-radius; */
  width: 100%;
  &__title {
    width: 100%;
    border-bottom: $input-border-small;
    h1 {
      font-size: 1.8rem;
      color: var(--main-text-color);
      font-weight: 600;
      line-height: 2rem;
      margin-bottom: 2rem;
    }
  }

  &-link {
    cursor: pointer;
  }

  &__container1 {
    padding: 2rem 0rem 1rem 0rem;
    width: 100%;
    border-bottom: $input-border-small;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
      flex-wrap: wrap;
    }

    &--left {
      margin-right: 1rem;
      h1 {
        font-size: 1.8rem;
        color: var(--main-text-color);
        font-weight: 600;
        line-height: 2rem;
        margin-bottom: 1rem;
      }
      p,
      span,
      li {
        font-size: 1.4rem;
        color: var(--main-text-color);
        font-weight: 600;
        line-height: 1.8rem;
        margin-bottom: 0.5rem;
      }

      li {
        list-style: circle;
        margin-left: 2rem;
      }
      span {
        color: $web-color-primary;
        font-weight: 400;
      }
    }
    &--right {
      @media only screen and (max-width: 500px) {
        margin-top: 1rem;
      }
    }
  }

  &__container2 {
    padding: 2rem 0rem 0rem 0rem;
    width: 100%;
    /* border-bottom: $input-border-small; */
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
      flex-wrap: wrap;
    }

    &--left {
      margin-right: 1rem;
      h1 {
        font-size: 1.8rem;
        color: var(--main-text-color);
        font-weight: 600;
        line-height: 2rem;
        margin-bottom: 1rem;
      }
      p,
      span {
        font-size: 1.4rem;
        color: var(--main-text-color);
        font-weight: 600;
        line-height: 1.8rem;
        margin-bottom: 0.5rem;
      }

      ul {
        margin-bottom: 2rem;
        li {
          font-size: 1.4rem;
          color: var(--main-text-color);
          font-weight: 500;
          line-height: 1.5rem;
          margin-bottom: 0.5rem;

          &:nth-child(1),
          &:nth-child(2) {
            span,
            a {
              color: $web-color-primary;
              font-weight: 400;
            }
          }
        }
      }
    }
    &--right {
      @media only screen and (max-width: 500px) {
        margin-bottom: 1rem;
      }
    }
  }
}
