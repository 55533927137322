@import '../../abstracts/variables.sass';
/* ------- footer styles start -------- */
.footer1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.footer1__top {
  width: 100%;
  height: 1px;
  background-color: transparent;
  border-bottom: $input-border-small;
  margin-bottom: 1rem;
}

.footer1__bottom {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-size: 0.7rem;
  color: $main-light-gray-font-color-3;
  font-family: var(--main-font-primary);
  font-weight: 400 !important;
}

.footer1__bottom h2 {
  font-size: 1.1rem;
  color: $main-light-gray-font-color-3;
  font-weight: 400;
}

.footer1__bottom--links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer1__bottom--links a {
  text-decoration: none;
  font-size: 1.1rem;
  color: $main-light-gray-font-color-3;
}
.footer1__bottom--links a:first-child {
  margin-right: 0.2rem;
}
.footer1__bottom--links a:last-child {
  margin-left: 0.2rem;
}
/* ------- footer styles end --------- */
