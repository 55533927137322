@import '../../abstracts/variables.sass';

.aiOnboardingCompany {
  &__title {
    font-size: 2.4rem;
    font-weight: 700 !important;
    color: var(--main-text-color);
    margin-bottom: 1.5rem;
  }

  &__button {
    width: 100%;
    margin-top: 0.5rem;
    .mainSquareButton {
      width: 100%;
      height: 4rem;
      font-size: 1.5rem;
      transition: $normal-transition;
      background-color: rgb(22, 22, 22);
      &:hover {
        background-color: rgb(36, 36, 36);
      }
    }
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    img {
      width: 3rem;
      height: 3rem;
    }
  }
}
