@import '../../abstracts/variables.sass';

.chart {
  margin-top: 2rem;
  // width: auto;
  width: 100%;
  padding: 2rem;
  border-radius: 0.8rem;
  background-color: var(--sidebar-background-color);
  border: 1px solid var(--main-border-color);
  -webkit-box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  height: 40rem;
  overflow-y: auto;
  -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
  scrollbar-width: none; /*Hide scrollbar for Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 750px) {
    height: auto;
  }

  &-goBack {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 2rem;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      fill: $main-dark-gray-font-color;
    }

    span {
      font-weight: 400;
      font-size: 1.3rem;
      color: $main-dark-gray-font-color;
    }
  }

  &__heading {
    &--title {
      font-size: 2rem;
      font-weight: 600;
      color: var(--main-text-color);
      margin-bottom: 0;
    }

    &--comment {
      margin: 0 !important;
      font-size: 1.5rem;
      font-weight: 400;
      color: $main-dark-gray-font-color;
    }
  }

  &__bottom {
    margin-top: 1rem;
    width: 100%;

    &--content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;

      &-chart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;

        &-pie {
          margin-right: 2rem;

          &[data-chart='funnel'] {
            width: 110rem;
            @media only screen and (max-width: 1200px) {
              width: 100rem;
            }
            @media only screen and (max-width: 1000px) {
              width: 50rem;
            }
            @media only screen and (max-width: 850px) {
              width: 40rem;
            }
            @media only screen and (max-width: 650px) {
              width: 30rem;
            }
            @media only screen and (max-width: 400px) {
              width: 25rem;
            }
          }

          //Chart outer label color changes
          .MuiChartsLegend-series {
            text {
              fill: var(--main-text-color) !important;
            }
          }
        }

        &-legend {
          min-width: 20rem;
          max-height: 20rem;
          overflow-y: auto;
          margin-top: 1rem;
          width: 100%;
          display: flex;

          &-content {
            display: flex;
            align-items: self-start;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
          }

          @media only screen and (max-width: 650px) {
            margin-top: 1rem;
            width: 100%;
          }
          &-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            // justify-content: center;
            margin-right: 1rem;
            width: 15rem;
            min-height: 4rem;
            &-color {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 0.5rem;
              border: var(--main-text-color) solid 1px;
              border-radius: 0.2rem;
              flex-shrink: 0; /* Prevent shrinking */
            }
            &-text {
              font-size: 1.4rem;
              color: $main-dark-gray-font-color;
              white-space: normal; /* Allow the text to wrap */
              word-wrap: break-word; /* Wrap long words */
            }
          }
        }
      }

      &-stats {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
        gap: 0.5rem;
        flex-wrap: wrap;

        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;
          }
          &-text {
            font-size: 1.4rem;
            color: $main-dark-gray-font-color;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;

            span:first-child {
              margin-right: 0.5rem;
            }
            span:last-child {
              font-size: 1.5rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
