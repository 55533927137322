@import '../../../abstracts/variables.sass';

.taraHeading {
  &__initial {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    h1 {
      font-size: 3.6rem;
      font-weight: 700;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
    }
  }
}
