@import '../../abstracts/variables.sass';

.headerTheme2 {
  display: none;
  height: $header-height;
  // left: $sidebar-width;
  width: 100%;
  // width: calc(100% - $sidebar-width);
  // background-color: var(--header-color);
  // background-color: var(--sidebar-color);
  background-color: var(--main-brand-color);
  /*   background-image: linear-gradient(
    to right,
    var(--header-gradient-color-1),
    var(--header-gradient-color-2)
  ); */
  // display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  right: 0;
  padding: 2rem;
  /* padding-left: 10rem;
  padding-right: 11rem; */
  position: fixed;
  -webkit-box-shadow: -15px 11px 15px -22px rgba(0, 0, 0, 0.59);
  box-shadow: -15px 11px 15px -22px rgba(0, 0, 0, 0.59);
  z-index: 10;

  &[data-header-is-dark-mode='true'] {
    -webkit-box-shadow: 1px 8px 17px -2px rgba(255, 255, 255, 0.062);
    box-shadow: 1px 8px 17px -2px rgba(255, 255, 255, 0.062);
  }

  @media only screen and (max-width: 750px) {
    display: none;
    /* padding-left: 2rem;
    padding-right: 2rem;
    top: $header-height;
    left: 0; */
    /* box-shadow: none; */
  }

  /* &[data-sidebar-open='false'] {
    left: 0;
  } */

  /*   &[data-sidebar-open='true'] > &__left {
    margin-left: 10rem;

    @media only screen and (max-width: 750px) {
      margin-left: 0rem;
    }
  } */

  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: $normal-transition;

    /* margin-left: 26rem;

    @media only screen and (max-width: 750px) {
      margin-left: 0;
    } */

    /* &[data-sidebar-open='true'] {
      margin-left: 4%;

      @media only screen and (max-width: 1300px) {
        margin-left: 8%;
      }
      @media only screen and (max-width: 1100px) {
        margin-left: 12%;
      }
      @media only screen and (max-width: 900px) {
        margin-left: 16%;
      }
    } */

    &--menu {
      cursor: pointer;
      margin-right: 1rem;
      svg {
        fill: var(--main-brand-color);
        height: 4rem;
      }

      @media only screen and (max-width: 750px) {
        margin-right: 0rem;
        svg {
          display: none;
        }
      }
    }

    &--title {
      font-size: 2.3rem;
      font-weight: 600;
      color: var(--main-text-color);
    }

    &--logo {
      height: $header-height;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: $normal-transition;
      img {
        height: 3.5rem;
      }
    }

    &--list {
      display: flex;
      align-items: center;
      margin-bottom: 0rem;
      margin-left: 2rem;
      li {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $normal-transition;
        background-color: var(--main-brand-hover-color);
        cursor: pointer;

        &:first-child {
          margin-left: 1rem;
        }

        /*  &:hover {
          -webkit-box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.8);
          box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.8);
        } */

        svg {
          height: 1.8rem;
          width: 1.8rem;
          fill: var(--main-btn-text-color);
          transition: $normal-transition;
          &:hover {
            fill: var(--secondary-brand-color);
          }
        }
      }
    }
  }

  &__right {
    &--list {
      display: flex;
      align-items: center;
      margin-bottom: 0rem;
      position: relative;
      li {
        width: 4.5rem;
        height: 4.5rem;
        border: 0.5rem solid #6a7a93;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $normal-transition;
        cursor: pointer;

        &::before {
          content: '';
          width: 1.6rem;
          height: 1.6rem;
          border: 0.3rem solid #ffffff;
          border-radius: 50%;
          position: absolute;
          top: 2.7rem;
          right: 1rem;
          background-color: #28c76f;
          z-index: 2;
          transition: $normal-transition;
        }

        &:hover {
          background-color: $main-white-color;
          -webkit-box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.8);
          box-shadow: 0px 0px 15px -7px rgba(0, 0, 0, 0.8);
        }

        svg {
          height: 2.7rem;
          width: 2.7rem;
          fill: var(--main-btn-text-color);
        }

        &:nth-child(1) {
          margin-right: 1rem;
        }
        &:nth-child(1) > svg {
          fill: #ffb62e;
        }

        /* &:nth-child(2) > img {
          object-fit: cover;
          height: 2.7rem;
          width: 2.7rem;
          border-radius: 50%;
        }

        &:last-child > img {
          object-fit: cover;
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
        }
        &:last-child {
          @media only screen and (max-width: 750px) {
            display: none;
          }
          border: $input-border-small;
        } */
      }
    }
  }
}
/* height: 10rem;
width: 100%;
background-color: rgb(98, 160, 95); */
