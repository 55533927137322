@import '../abstracts/variables.sass';

.noData {
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 20rem;
    object-fit: contain;
  }
  h1 {
    font-size: 2rem;
    font-weight: 500 !important;
    font-family: var(--main-font-primary);
    color: $main-light-gray-font-color-5;
    margin: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.4rem;
    font-weight: 400 !important;
    font-family: var(--main-font-secondary) !important;
    color: $main-light-gray-font-color-2;
    margin: 2 4rem;
    text-align: center;
  }
  &__error {
    svg {
      height: 5rem;
      width: 5rem;
      fill: $web-color-danger;
    }
  }
  &__success {
    svg {
      height: 5rem;
      width: 5rem;
      fill: var(--main-brand-color);
    }
  }
}
