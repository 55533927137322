@import '../../abstracts/variables.sass';

.tara {
  background-color: var(--sidebar-background-color);
  border-radius: 0.8rem;
  width: 100%;
  min-height: calc(100vh - #{$header-height + 12rem});
  height: calc(100vh - #{$header-height + 12rem});
  margin-top: 1rem;
  padding: 4rem 2rem;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-new {
    height: auto;
  }

  &-active {
    padding: 4rem 0rem;
    .taraInput__container {
      position: absolute;
      z-index: 1;
      bottom: -2rem;
      width: 75rem;
      // margin-left: -0.8rem;

      @media only screen and (max-width: 1080px) {
        width: 80%;
      }
    }
  }
}
