@import '../../abstracts/variables.sass';

.orderDetailPage {
  display: flex;
  flex-direction: column;
  &-backButton {
    align-self: flex-end;
    button > svg {
      fill: var(--icon-color);
      height: 1.7rem;
    }
  }
  &__top {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      /* align-items: center; */
    }
    &--left,
    &--center,
    &--right {
      width: 100%;
      margin-left: 2rem;
      @media only screen and (max-width: 1000px) {
        padding: 2rem;
        margin-left: 0rem;
        width: 100%;
      }

      h1 {
        color: var(--main-text-color);
        font-size: 1.9rem;
        font-weight: 600;
      }

      h2 {
        font-size: 1.4rem;
        color: var(--main-text-color);
        font-weight: 600;
      }

      span {
        color: var(--main-text-color);
        font-weight: 400 !important;
        font-size: 1.35rem;
        font-family: var(--main-font-primary);
      }
    }
    &--center {
      &-status {
        height: 2rem;
        display: flex;
        align-items: center;

        span {
          margin-left: 0.5rem;
        }
        div {
          margin-left: 0.5rem;
          height: 1.4rem;
          width: 1.4rem;
          background-color: $web-color-success;
          border-radius: 50%;
        }
      }
      &-statusInactive {
        height: 2rem;
        display: flex;
        align-items: center;

        span {
          margin-left: 0.5rem;
        }
        div {
          margin-left: 0.5rem;
          height: 1.4rem;
          width: 1.4rem;
          background-color: #dc3545;
          border-radius: 50%;
        }
      }
    }
    &--right {
      &-billing {
        margin-top: 2rem;
      }
      span {
        color: var(--main-text-color);
        font-weight: 400 !important;
        font-size: 1.35rem;
        font-family: var(--main-font-primary);
      }
    }
  }

  &--variants-text {
    /* text-align: center; */
    font-size: 1.5rem;
    color: var(--main-text-color);
    font-weight: 500;
    margin-top: 4rem;
    border-top-left-radius: $normal-border-radius;
    border-top-right-radius: $normal-border-radius;
    border-top: $input-border-small;
    border-left: $input-border-small;
    border-right: $input-border-small;
    padding: 1rem;
  }

  &__bottom {
    /* overflow-x: scroll; */
  }
}
