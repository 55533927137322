@import '../abstracts/variables.sass';

.customDataGrid {
  width: 100%;
  /* margin-top: 2rem; */

  h2,
  p {
    margin-bottom: 0;
    font-family: var(--main-font-secondary) !important;
  }

  &__items[data-datagrid-boarder-type='none'] {
    border: none;
  }
  &__items[data-datagrid-boarder-type='round'] {
    border-radius: $normal-border-radius;
  }

  &__items {
    width: 100%;
    border: $input-border-small;
    li {
      border-bottom: $input-border-small;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #ccc; */
    align-items: center;
    padding: 1rem;
    min-height: 7.5rem;
    width: 100%;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      height: auto;
      align-items: flex-start;
    }

    &--left {
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-grow: 1;

      @media only screen and (max-width: 800px) {
        width: 100%;
      }

      &-img {
        cursor: pointer;
        margin-right: 1rem;
        flex-shrink: 0;
        img {
          border: $input-border-small;
          min-height: 5rem;
          min-width: 5rem;
          height: 5rem;
          width: 5rem;
          object-fit: contain;
          border-radius: $normal-border-radius;
        }

        video {
          border: $input-border-small;
          min-height: 5rem;
          min-width: 5rem;
          height: 5rem;
          width: 5rem;
          object-fit: contain;
          border-radius: $normal-border-radius;
        }
      }

      &-content {
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: column;
        /* align-items: flex-end; */
        justify-content: space-between;
        flex-grow: 1;
        h2 {
          font-size: 1.4rem;
          font-weight: 500;
          height: 1.8rem;
          overflow: hidden;
          /* margin-bottom: 0.5rem; */
          color: var(--main-text-color);
        }

        &-items {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          min-height: 3rem;

          &-pill {
            background-color: $web-color-secondary;
            color: #fff;
            padding: 0.3rem 1.2rem;
            border-radius: 1rem;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: bold;
          }

          &-sku-Simple {
            background-color: #5680e9;
          }
          &-sku-Variable {
            background-color: #00abff;
          }

          &-status-Active,
          &-status-Success,
          &-status-Paid {
            background-color: $web-color-success;
          }
          &-status-Completed {
            background-color: $web-color-success;
          }
          &-status-Inactive,
          &-status-Failed,
          &-status-Cancelled {
            background-color: $web-color-danger;
          }
          &-status-Pending {
            background-color: rgb(239, 190, 57);
          }
          &-status-Refunded {
            background-color: #5ab9ea;
          }

          div {
            display: flex;
            justify-content: center;
            border-right: 0.5px solid var(--containers-border-color);
            margin-right: 0.5rem;
            margin-top: 0.5rem;

            &:last-child {
              border-right: none;
            }

            h2 {
              font-size: 1.5rem;
              font-weight: 500;
              color: var(--main-text-color);
              margin-bottom: 0;
              line-height: 1.5rem !important;
              margin-right: 0.5rem;
            }

            h3 {
              font-size: 1.4rem;
              font-weight: 400;
              color: var(--main-text-color);
              margin-bottom: 0;
              line-height: 1.5rem !important;
              margin-right: 0.5rem;
            }
          }
        }
      }

      &-date {
        h2 {
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 0.9rem;
          color: var(--main-text-color);
          @media only screen and (max-width: 800px) {
          }
        }
      }
    }

    &--right[data-has-datagrid-date='true'] {
      @media only screen and (max-width: 800px) {
        justify-content: space-between;
      }
    }
    &--right[data-has-datagrid-date='false'] {
      @media only screen and (max-width: 800px) {
        justify-content: flex-end;
      }
    }

    &--right {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-left: 1rem;

      @media only screen and (max-width: 800px) {
        margin-top: 1rem;
        width: 100%;
        flex-direction: row-reverse;
        /* justify-content: space-between; */
        align-items: center;
        margin-left: 0rem;
      }

      h2 {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 0.9rem;
        text-align: right;
        margin-left: 1rem;
        color: var(--main-text-color);
        @media only screen and (max-width: 800px) {
          margin-bottom: 0rem;
        }
      }

      &-action {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        svg {
          height: 1.3rem;
          width: 1.3rem;
          cursor: pointer;
        }

        &-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem 1.5rem;
          border-radius: $button-border-radius;
          cursor: pointer;
          border: $input-border-small;
          transition: $normal-transition;
          margin: 0rem 0.5rem;

          &:last-child {
            margin-right: 0rem;
          }

          &:hover {
            border: $input-border-small-hover;
          }
        }

        &-delete {
          svg {
            fill: $web-color-danger;
          }
        }
        &-edit {
          svg {
            fill: $web-color-success;
          }
        }
        &-share {
          svg {
            fill: $web-color-info;
          }
        }
        &-disabled {
          opacity: 70%;
          background-color: $web-color-disabled !important;
          cursor: not-allowed;
          svg {
            fill: $web-color-dark !important;
            cursor: not-allowed;
          }
          &:hover {
            border: $input-border-small;
          }
        }
      }
    }
  }
}
