@import '../../abstracts/variables.sass';

.tabs {
  width: 100%;
  display: flex;
  padding: 0.5rem;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: $normal-transition;
  background-color: var(--subPageLinkHolder-link-hover-color);
  gap: 0.5rem;

  @media only screen and (max-width: 750px) {
    margin-top: 2rem;
  }

  &__tab {
    flex-grow: 1;
    border-radius: 0.2rem;
    padding: 0.5rem;
    transition: $normal-transition;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      color: $main-light-gray-font-color-5;
      font-family: var(--main-font-primary) !important;
      margin-bottom: 0;
      text-align: center;
    }

    &--active {
      background-color: var(--sidebar-background-color);
      h2 {
        color: var(--main-text-color);
      }
    }
  }
}
