:root {
  --bg: #ffffff00;
  --header: #7a7a7a;
  --color: #000000;
}

.container {
  min-height: 100vh;
  display: flex;
  font-family: var(--main-font-primary);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color);
  //   perspective: 120rem;
  perspective: 100%;
}
.info {
  text-align: center;
  line-height: 1.5;
  font-size: 1.6rem;
  max-width: clamp(16rem, 90vmin, 25rem);
  text-transform: uppercase;
}
.info > h2 {
  color: var(--header);
}
.info > p {
  margin-bottom: 3rem;
  font-family: var(--main-font-secondary) !important;
}
