@import '../../abstracts/variables.sass';

.settingsShippingOptions {
  padding-top: 2rem;
  &__top {
    &--toggle {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
}
