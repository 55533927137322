@import '../abstracts/variables.sass';

@keyframes pulsate {
  from {
    color: #71717a;
  }
  to {
    color: var(--main-text-color);
  }
}

.onboardingCompleteBanner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $popup-overlay-color;
  z-index: 10000;
  background-color: var(--input-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--text {
      h1 {
        text-align: center;
        color: var(--main-text-color);
        font-size: 3.5rem;
        line-height: 3.5rem;
        font-weight: 700;
        letter-spacing: 0.01rem;
        margin-bottom: 1rem;
      }

      p {
        text-align: center;
        color: #71717a;
        font-weight: 400;
        font-size: 1.4rem;
        margin-bottom: 0;
      }
    }
    &--animation {
      margin-top: 4rem;
      svg {
        width: 10rem;
        height: 10rem;
      }

      p {
        margin-top: 2rem;
        text-align: center;
        color: #71717a;
        font-weight: 400;
        font-size: 2rem;
        margin-bottom: 0;
        animation: pulsate 1000ms alternate infinite ease;
        transition: color 500ms ease;
      }
    }
  }
}
