@import '../../abstracts/variables.sass';

.trends {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: var(--main-text-color);
    margin: 1rem 2rem;
  }

  &-lottie {
    height: 35rem;
    width: 35rem;
  }
}

.recommendations-payment-popup-custom {
  .popup__modal {
    -ms-overflow-style: none; /*Hide scrollbar for IE and Edge */
    scrollbar-width: none; /*Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    @media only screen and (max-width: 900px) {
      top: 2rem;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      transform: none !important;
      margin-left: auto;
      margin-right: auto;
    }

    @media only screen and (max-width: 550px) {
      width: 95%;
      top: 1rem;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
    }
  }

  .popup__modal--closeBtn {
    @media only screen and (max-width: 1000px) {
      top: 2rem !important;
      right: 2rem !important;
    }
  }
}

.recommendationsTabs {
  display: flex;
  // justify-content: space-evenly;
  align-items: center;

  &[data-recommendations-tab='new'] {
    .recommendationsTabs__tab--new {
      border-bottom: 2px solid var(--black-white-color);
      h2 {
        color: var(--main-text-color) !important;
      }
      span {
        color: var(--main-text-color) !important;
      }
    }
  }
  &[data-recommendations-tab='active'] {
    .recommendationsTabs__tab--active {
      border-bottom: 2px solid var(--black-white-color);
      h2 {
        color: var(--main-text-color) !important;
      }
      span {
        color: var(--main-text-color) !important;
      }
    }
  }
  &[data-recommendations-tab='archived'] {
    .recommendationsTabs__tab--archived {
      border-bottom: 2px solid var(--black-white-color);
      h2 {
        color: var(--main-text-color) !important;
      }
      span {
        color: var(--main-text-color) !important;
      }
    }
  }

  &__tab {
    padding: 1rem 2rem;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: $normal-transition;
    border-bottom: 2px solid $main-light-gray-font-color-5;
    // background-color: var(--subPageLinkHolder-link-hover-color);

    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--main-text-color);
      color: $main-light-gray-font-color-5;
      font-family: var(--main-font-primary) !important;
      margin-bottom: 0;
      margin-right: 0.5rem;
    }

    span {
      font-size: 1.3rem;
      color: $main-light-gray-font-color-5;
      font-family: var(--main-font-primary) !important;
      margin-bottom: 0;
      font-weight: 500 !important;
    }
  }
}
