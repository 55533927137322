.aiCheckoutTrackingStats {
  &--container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // padding: 2rem;
    // margin-top: 2rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  // width: 80%;
  width: 100%;

  .simpleCard {
    // width: 100% !important;
    margin: 0 !important;
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      color: var(--main-text-color);
      margin: 1rem 2rem;
    }

    &--lottie {
      height: 35rem;
      width: 35rem;
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
}
