@import '../abstracts/variables.sass';

.slideFromBottom {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1000;

  &-backdrop {
    margin: 0;
    padding: 0;
    top: 0;
    min-width: 100vw;
    min-height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  &-prompt {
    background-color: var(--containers-background-color);
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 1.5rem 1.5rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.2rem;
    box-sizing: border-box;

    &-container {
      width: 40rem;
      padding: 3rem;
      padding-top: 0;
      @media only screen and (max-width: 400px) {
        width: 100%;
      }
    }

    /* h1 {
      font-family: var(--main-font-primary);
      margin-bottom: 0;
      color: var(--main-text-color);
      font-weight: 700;
      font-size: 2.1rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.7rem;
      line-height: 2.7rem;
      margin-bottom: 1.4rem;
      color: var(--main-text-color);
      font-weight: 400;
      font-family: var(--main-font-primary);
      margin-top: 1rem;
    } */
  }
}
