@import '../../../abstracts/variables.sass';

.taraSearchSuggestions {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 70rem;
}
