@import '../../abstracts/variables.sass';

.paginationTheme2 {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--sidebar-background-color);
  padding: 2rem;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
    align-items: end;
  }

  &__left {
    display: flex;
    align-items: center;
    span {
      color: rgb(151, 151, 151);
      font-size: 1.3rem;
      font-weight: 500;
      margin-right: 1rem;
    }

    select {
      border: 1px solid var(--subPageLinkHolder-link-hover-color);
      border-radius: 0.5rem;
      padding: 0.5rem;
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--main-text-color);
      background-color: var(--sidebar-background-color);
      cursor: pointer;
      transition: $normal-transition;
      margin-right: 1rem;
      outline: none;
      &:hover {
        border-color: var(--subPageLinkHolder-link-hover-color);
      }
    }
  }

  &__right {
    flex-grow: 1;
    @media only screen and (max-width: 750px) {
      width: 100%;
      margin-top: 1rem;
    }

    .pagination {
      justify-content: flex-end;
      margin-top: 0;
      margin-bottom: 0;
      height: auto;
      /* width: 100%;
      list-style: none;
      padding-left: 0 !important;
      display: flex;
      align-items: center;
      align-items: center;
      font-size: 1.2rem;
      gap: 0.5rem;
      flex-wrap: wrap; */

      a {
        height: 3rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__page-num {
        padding: 0.5rem 1.2rem;
        border: none;
        background-color: var(--gray-btn-light-background-color) !important;
        /* font-weight: 500 !important;
          font-family: var(--main-font-primary);
          transition: $normal-transition;
          border-color: transparent;
          color: var(--main-btn-color);
          text-align: center;
          white-space: nowrap;
          cursor: pointer;
          border-radius: $button-border-radius; */

        &:hover {
          border: none;
          // color: var(--main-btn-color);
        }
        &:active {
          box-shadow: none;
        }

        &-disabled {
          border: none;
          background-color: transparent;
          /* cursor: not-allowed;
            box-shadow: none;
            color: var(--gray-btn-light-text-color); */
          &:hover {
            /* background-color: var(--gray-btn-light-background-color);
              color: var(--gray-btn-light-text-color); */
            border: none;
          }
        }
      }

      &__active {
        border: none;
        background-color: var(--main-btn-color) !important;
        color: var(--main-btn-text-color);
        transition: $normal-transition;
        &:hover {
          // color: var(--main-btn-text-color);
          border: none;
        }
      }
    }
  }

  /* ------------ */
  &--next-label {
    background-color: var(--gray-btn-light-background-color);
    border-radius: $button-border-radius;
    svg {
      height: 1rem;
      width: 1rem;
      fill: var(--gray-btn-light-text-color);
      transform: rotate(180deg);
    }
  }
  &--prev-label {
    background-color: var(--gray-btn-light-background-color);
    border-radius: $button-border-radius;
    svg {
      height: 1rem;
      width: 1rem;
      fill: var(--gray-btn-light-text-color);
    }
  }
}
