@import '../../abstracts/variables.sass';

.splashScreenNew {
  min-height: 100vh;
  background: linear-gradient(
    to bottom right,
    var(--sidebar-color),
    var(--sidebar-hover-color)
  );
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    @media (min-width: 640px) {
      padding: 0 1.5rem;
    }

    @media (min-width: 1024px) {
      padding: 0 5rem;
    }

    &--text {
      text-align: center;

      h1 {
        margin-top: 1.5rem;
        font-size: 2.5rem;
        font-weight: 500;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 750px) {
          font-size: 2.25rem;
        }
      }
    }

    &--animation {
      margin: 0 auto;
      width: 100%;
      max-width: 24rem;

      &-container {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;

        svg {
          height: 25rem;
          width: 25rem;

          @media only screen and (max-width: 750px) {
            height: 20rem;
            width: 20rem;
          }
        }

        p {
          font-size: 1.25rem;
          font-weight: 500;
          color: var(--main-text-color);
          font-family: var(--main-font-primary) !important;
        }
      }
    }
  }
}
