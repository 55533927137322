@import '../../abstracts/variables.sass';

.settingsSecurityQR {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;

  &__QR {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      height: 20rem;
      width: 20rem;
      margin-bottom: 1rem;
    }
    p {
      text-align: center;
      font-weight: 500;
      font-size: 1.2rem;
      color: #9999a9;
      line-height: 1.2rem;
      font-family: var(--main-font-secondary) !important;
    }
    margin-bottom: 1rem;
  }
  &__OTP {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
