@import '../abstracts/variables.sass';

.imageInput {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15rem;
  margin: 2rem;

  &-outer {
    position: relative;
    overflow: hidden;
    border: $input-border-1;
    border-radius: 0.8rem;
    height: 20rem;
    width: 100%;
    transition: $normal-transition;
    display: flex;
    justify-content: center;
    margin-top: 1.6rem;
    margin-bottom: 1rem;
    align-items: center;
    background-color: var(--input-background-color);
    font-family: var(--main-font-primary) !important;

    &-dragOver {
      border: $input-border-1-focus;
    }

    &:hover {
      border: $input-border-2;
    }

    &--label {
      position: absolute;
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--main-text-color);
      left: 1rem;
      top: 0.5rem;
      transition: $normal-transition;
      font-family: var(--main-font-primary) !important;
    }

    &--close {
      position: absolute;
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--main-text-color);
      right: 1rem;
      top: 0.5rem;
      transition: $normal-transition;
      font-family: var(--main-font-primary) !important;
      cursor: pointer !important;
    }
  }

  &__top {
    height: 7.5rem;
    width: 7.5rem;
    background-color: var(--input-background-color);
    border: 1px solid rgb(197, 197, 197);
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: 0.5rem;
    label {
      font-family: var(--main-font-primary) !important;
      img {
        height: 100%;
        width: 100%;
        cursor: pointer;
        object-fit: contain;
      }

      div {
        height: 4rem;
        width: 4rem;
        transition: all 0.3s ease;
        &:hover {
          cursor: pointer;
        }

        svg {
          transition: all 0.3s ease;
          fill: rgb(197, 197, 197);
          height: 100%;
          width: 100%;

          &:hover {
            fill: var(--main-brand-color);
          }
        }
      }
    }

    input {
      display: none;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      color: var(--main-text-color);
      font-size: 1.4rem;
      margin-bottom: 0rem;
      font-family: var(--main-font-primary) !important;

      &[error='false'] {
        color: #a2a5b9;
      }
      &[error='true'] {
        color: crimson;
      }
    }

    span {
      font-family: var(--main-font-secondary) !important;
      font-size: 1.1rem;
      font-weight: 300 !important;
      color: #a2a5b9;
      width: 20rem;
      text-align: center;
    }

    a {
      font-family: var(--main-font-primary);
      font-size: 1.1rem;
      font-weight: 300 !important;
      color: $web-color-danger;
      max-width: 100%;
      text-align: center;
    }
  }
}
