@import '../../abstracts/variables.sass';

.stripeSubscriptionFailed {
  height: 100vh;
  width: 100vw;
  background-color: $popup-overlay-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &__message {
    border-radius: 1.5rem;
    background-color: $main-white-color;
    min-height: 50rem;
    width: 70rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;

    @media only screen and (max-width: 800px) {
      width: 80%;
      padding: 4rem 2rem;
    }

    &--icon {
      svg {
        height: 12rem;
        width: 12rem;
        fill: $web-color-danger;
      }
      margin-bottom: 3rem;
    }

    &--title {
      h1 {
        color: var(--main-text-color);
        font-size: 2.8rem;
        text-align: center;
      }
    }

    &--body {
      width: 90%;
      p {
        font-size: 1.6rem;
        color: $main-light-gray-font-color-5;
        text-align: center;
        font-family: var(--main-font-secondary) !important;
      }
    }

    &--button {
      margin-top: 2rem;
      button {
        padding: 1rem 4rem;
      }
    }
  }
}
