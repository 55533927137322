@import '../../../abstracts/variables.sass';

.autofillInfo {
  h1 {
    font-size: 1.5rem;
    color: var(--main-text-color);
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  &__steps {
    &--item {
      margin-top: 1.5rem;
      h2 {
        color: var(--main-text-color);
        margin-bottom: 0rem;
      }
      &-example {
        color: var(--main-text-color);
        margin-left: 2rem;
        font-weight: 500;
      }
      &-codeblock {
        margin-top: -2rem;
      }
    }
  }

  /*   @media only screen and (max-width: 500px) {
      margin-left: 0;
    } */
}
