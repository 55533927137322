@import '../../abstracts/variables.sass';

.subPageListDelete {
  padding: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 6rem;
    height: 6rem;
    fill: $web-color-danger;
    margin-bottom: 1rem;
  }
  h1 {
    font-size: 2.2rem;
    color: var(--main-text-color);
    margin-bottom: 1rem;
    font-weight: 400;
    text-align: center;
  }
  &__buttons {
    margin-top: 1rem;
    display: flex;
    button {
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}
