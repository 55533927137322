@import '../../abstracts/variables.sass';

.pageWrapper {
  // margin-left: $sidebar-width;
  // width: calc(100% - $sidebar-width);
  background-color: var(--main-background-color);
  // padding-left: 19rem;
  /*  margin-right: 2%;
  margin-left: 2%; */
  padding-top: $header-height + 2rem;
  overflow: hidden;
  min-height: 100vh;
  padding-left: 2rem;
  padding-right: 2rem;

  &--outer {
  }
  &--footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media only screen and (max-width: 750px) {
    /* padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0; */
    width: 100%;
    /* margin-top: ($header-height) * 2 + 2rem; */
  }

  &__content {
    display: flex;

    &--sidebar {
      transition: all 0.5s ease;
      min-height: 100vh;
      @media only screen and (max-width: 750px) {
        position: fixed;
        z-index: 2;
        left: -1rem;
        top: $header-height;
      }
      &[data-is-sidebar-open='false'] {
        @media only screen and (max-width: 750px) {
          left: -85%;
        }
      }
    }
    &--page {
      margin-left: 2rem;
      width: 50%;
      @media only screen and (max-width: 750px) {
        margin-left: 0;
      }
      flex-grow: 1;
    }
  }
}
