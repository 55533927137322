@import '../abstracts/variables.sass';

.popup {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $popup-overlay-color;
    z-index: 1000;
    cursor: pointer;
  }

  &__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $normal-border-radius;
    border: 1rem solid transparent;
    background-color: var(--input-background-color) !important;
    /* background-color: #fff; */
    /* background-color: transparent; */
    padding: 1.7rem 1.7rem;
    /* padding-top: 20%; */
    z-index: 1000;
    // width: 70%;
    max-width: 100rem;
    max-height: 100vh;
    overflow-y: auto;

    &--closeBtn {
      position: fixed;
      height: 2rem;
      width: 2rem;
      top: 1rem;
      right: 1rem;
      z-index: 1001;
      cursor: pointer;
      svg {
        height: 100%;
        width: 100%;
        fill: $main-dark-gray-font-color;
      }
    }

    @media only screen and (max-width: 800px) {
      width: 80%;
    }
    @media only screen and (max-width: 400px) {
      width: 90%;
    }
  }
}
