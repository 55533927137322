@import '../abstracts/variables.sass';

$gap: 3rem;

.customDataGridTheme2 {
  overflow-x: auto;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  // border-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  padding: 0.5rem;
  background-color: var(--sidebar-background-color);
  margin-top: 2rem;

  &__wrapper {
    min-width: 100%;
    display: inline-block;
  }

  &__columns {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--subPageLinkHolder-link-hover-color);
    min-width: 100%;

    &--title {
      flex-shrink: 0;
      margin-right: $gap;
      span {
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
        color: rgb(151, 151, 151);
      }
    }

    &--title-action {
      margin-left: auto;
      flex-shrink: 0;
      span {
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
        color: rgb(151, 151, 151);
      }
    }

    &--title-select {
      margin-right: 3rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &__rows {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 100%;
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--subPageLinkHolder-link-hover-color);
    min-width: 100%;
    cursor: pointer;

    /* &:nth-child(even) {
      background-color: var(--subPageLinkHolder-link-hover-color);
    } */

    /*  &:last-child {
      border-bottom: none;
    } */

    &--image {
      margin-right: $gap;
      flex-shrink: 0;
      img {
        border: 1px solid var(--subPageLinkHolder-link-hover-color);
        object-fit: contain;
        border-radius: 0.8rem;
      }
    }

    &--select {
      margin-right: 3rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    &--action {
      margin-left: auto;
      flex-shrink: 0;
      display: flex;
      gap: 0.5rem;

      svg {
        cursor: pointer;
        transition: all 0.3s;
        height: 1.5rem;
        width: 1.5rem;
        fill: gray;
        margin-right: 1rem;
        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &--text {
      flex-shrink: 0;
      margin-right: $gap;
      span {
        font-size: 1.3rem;
        font-weight: 400;
        color: var(--main-text-color);
      }
    }
  }
}
