@import '../../../abstracts/variables.sass';

.taraChatItemAI {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__message {
    background-color: var(--sidebar-background-color);
    color: var(--main-text-color);
    border-radius: 0.8rem;
    padding: 1rem;
    // max-width: 100%;
    width: 100%;
    // max-width: 40rem;
    word-wrap: break-word;
    white-space: pre-wrap; // Changed from pre-line for better wrapping
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
    margin-top: -0.7rem;
    // box-shadow: $normal-box-shadow;
    overflow: hidden;

    &[data-is-typing='true'] {
      box-shadow: none;
    }

    /* Markdown content styling */
    .react-markdown {
      // List styling fixes
      ol,
      ul {
        padding-left: 2rem;
        margin: 0rem 0 !important;
      }

      li {
        margin: 0rem 0 !important;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0.5rem 0;
        color: var(--main-text-color);
      }
      h1,
      h2,
      h3 {
        font-weight: 600;
      }

      h4,
      h5,
      h6 {
        font-weight: 500;
      }

      pre {
        margin-bottom: 0.5rem;
        background: var(--subPageLinkHolder-link-hover-color) !important;
        code {
          color: var(--main-text-color);
        }
      }

      p,
      span {
        color: var(--main-text-color);
        margin-bottom: 0 !important;
      }

      // li {
      //   margin-bottom: 0.5rem;
      //   padding-left: 1rem;
      //   position: relative;
      //   text-wrap: pretty;
      // }

      // ol {
      //   counter-reset: list-counter;

      //   li {
      //     &::before {
      //       content: counter(list-counter) '. ';
      //       counter-increment: list-counter;
      //       position: absolute;
      //       left: -1.5rem;
      //       color: var(--main-text-color);
      //     }
      //   }
      // }

      // Existing styles
      strong {
        font-weight: 600;
        color: var(--main-text-color);
      }

      em {
        font-style: italic;
      }

      del {
        text-decoration: line-through;
      }

      a {
        color: $main-blue-hover;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }

      code {
        background: var(--subPageLinkHolder-link-hover-color);
        padding: 0.2rem 0.4rem;
        border-radius: 0.3rem;
        font-family: monospace;
      }
    }

    &--loading {
      display: inline-flex;
      align-items: center;
      gap: 0.4rem;
      height: 2.4rem;
      padding: 0.4rem 0;

      &-spinner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 2.4rem;
        height: 100%;

        span {
          width: 0.6rem;
          height: 0.6rem;
          background-color: #888;
          border-radius: 50%;
          animation: bounce 1.4s infinite ease-in-out both;

          &:nth-child(1) {
            animation-delay: -0.32s;
          }
          &:nth-child(2) {
            animation-delay: -0.16s;
          }
          &:nth-child(3) {
            animation-delay: 0s;
          }
        }
      }
    }

    @keyframes bounce {
      0%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-0.8rem);
      }
    }
  }

  &__chart {
    margin-top: -2rem;
    width: 75rem;

    @media only screen and (max-width: 1080px) {
      width: 100%;
    }
  }

  &__icon {
    background-color: var(--subPageLinkHolder-link-hover-color);
    margin-right: 1rem;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
  }
}
