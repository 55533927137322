@import '../abstracts/variables.sass';

.resetPassword {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  overflow-y: auto;

  &__logo {
    position: fixed;
    top: 3rem;
    height: 5rem;
    display: none;
    img {
      height: 100%;
    }

    @media only screen and (max-width: 850px) {
      display: block;
    }
  }

  &__content {
    background-color: var(--sidebar-background-color);
    padding: 2rem;
    padding-top: 1rem;
    width: 45rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 950px) {
      width: 40rem;
    }

    @media only screen and (max-width: 850px) {
      border-radius: 0.8rem;
      border: 1px solid $main-light-gray-font-color-2;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      background-color: var(--sidebar-background-color);
    }
    @media only screen and (max-width: 450px) {
      width: 100%;
    }
  }

  &__left {
    position: relative;
    height: 100vh;
    width: 50%;
    background-color: var(--sidebar-background-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 850px) {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
      background-color: var(--main-background-color);
      height: 100%;
    }

    &--validation {
      max-width: 30rem;
      position: absolute;
      z-index: 2;
      margin-top: 1rem;
      margin-bottom: 2rem;
      background-color: var(--sidebar-background-color);
      padding: 1rem;
      border-radius: 0.7rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      border: 1px solid $main-light-gray-font-color-2;

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
          }

          &-x {
            svg {
              margin-left: 0.3rem;
              width: 1.3rem;
              height: 1.3rem;
              margin-right: 1.4rem;
            }
          }
        }

        p {
          margin: 0;
          font-size: 1.5rem;
          font-weight: 400;
          color: var(--main-text-color);
          font-family: var(--main-font-secondary) !important;
        }
      }
    }

    &--top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 5rem;
      margin-bottom: 4rem;

      @media only screen and (max-width: 850px) {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      h1 {
        font-size: 2.5rem;
        font-weight: 500;
        color: var(--main-text-color);
        // color: var(--main-brand-color);

        font-family: var(--main-font-primary) !important;
      }

      p {
        max-width: 90%;
        font-size: 1.5rem;
        font-weight: 400;
        color: var(--main-text-color);
        line-height: 2rem;
        font-family: var(--main-font-secondary) !important;
      }
    }

    &--center {
      &-password {
        position: relative;
      }
      span {
        display: flex;

        .normalInput:first-child {
          margin-right: 1.6rem;
        }
      }

      &-links {
        margin-top: 1.6rem;
        font-size: 1.3rem;
        font-weight: 400;
        color: var(--main-text-color);
        line-height: 2rem;
        display: flex;

        span {
          cursor: pointer;
          color: $main-light-gray-font-color-4;
          transition: $normal-transition;
          font-weight: 600;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        span:hover {
          color: var(--main-brand-color);
        }
      }
    }

    &--bottom {
      width: 100%;
      margin-top: 1rem;

      &-button {
        width: 100%;
        margin-top: 1rem;
        .mainSquareButton {
          width: 100%;
          height: 4rem;
          font-size: 1.5rem;
          transition: $normal-transition;
          background-color: rgb(22, 22, 22);
          &:hover {
            background-color: rgb(36, 36, 36);
          }

          &:disabled {
            background-color: rgb(22, 22, 22);
            cursor: not-allowed;

            &:hover {
              background-color: rgb(36, 36, 36);
            }
          }
        }
      }

      &-links {
        margin-top: 1em;
        width: 100%;
        text-align: center;

        h3 {
          margin-bottom: 0rem;
          color: #71717a;
          font-weight: 400;
          font-size: 1.3rem;
        }

        h2 {
          //   margin-top: 3rem;
          margin-bottom: 0rem;
          color: #71717a;
          font-weight: 400;
          font-size: 1.3rem;
          span {
            cursor: pointer;
            color: $web-color-primary;
            margin-left: 0.1rem;
            margin-right: 0.1rem;
            font-weight: 500;
          }
        }
      }
    }

    &--other {
      width: 100%;
      margin-top: 2rem;
      border-top: $input-border-small;
      display: flex;

      img {
        flex-grow: 1;
        margin-top: 2rem;
        object-fit: contain;
        height: 4rem;
        border: $input-border-small;
        cursor: pointer;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: var(--login-background-color);
    height: 100%;
    width: 50%;
    @media only screen and (max-width: 850px) {
      display: none;
    }

    &--image {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        object-fit: contain;
        width: 55%;
      }

      &-lottie {
        width: 40%;
        object-fit: contain;
      }
    }
  }
}
