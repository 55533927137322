@import '../../../abstracts/variables.sass';

.taraSearch {
  width: 100%;
  min-height: calc(100vh - #{$header-height + 11rem});
  &__items {
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;

    .paginationTheme2 {
      padding: 2rem 0.5rem !important;
    }
  }
}
