@import '../../abstracts/variables.sass';

.subPageContentHolder {
  width: 100%;
  height: 100%;
  // border: $input-border-small;
  // box-shadow: $normal-box-shadow;
  background-color: var(--containers-background-color);
  // border-radius: $normal-border-radius;
  transition: $normal-transition;
  // margin-left: 2rem;
  // margin-bottom: 2rem;
  overflow: hidden;

  @media only screen and (max-width: 1000px) {
    /* margin-left: 0; */
    // margin-bottom: 2rem;
  }

  /* Theme switching section Start */
  //These styles are used for theme switching
  &[data-sub-page-content-theme='2'] {
    .subPageInnerContentHolder__content {
      padding-top: 10rem !important;
      @media only screen and (max-width: 750px) {
        padding-top: 0rem !important;
      }
    }
    .subPageInnerContentHolder__content--top {
      border-bottom: 1px solid var(--main-border-color) !important;
      padding: 2rem;
      position: fixed;
      top: 0;
      left: $theme-2-sidebar-width;
      right: 0;
      z-index: 2;
      background-color: var(--sidebar-background-color);
      margin-left: 0 !important;
      margin-right: 0 !important;
      transition: $normal-transition;

      @media only screen and (max-width: 750px) {
        position: static;
        z-index: 0;
      }

      &-buttons {
        gap: 1rem;
      }
    }

    .subPageInnerContentHolder__content--top[data-is-sidebar-minimized='true'] {
      left: 7rem;
    }

    .subPageInnerContentHolder__content--bottom {
      padding-top: 0 !important;
    }

    .subPageInnerContentHolder__content--top-right-title {
      h3 {
        font-size: 2rem !important;
        font-weight: 600 !important;
        margin-bottom: 0.2rem !important;
        text-wrap: wrap !important;
        line-height: 2.2rem !important;
      }
    }
  }
  /* Theme switching section End */
}
