@import '../abstracts/variables.sass';

.multipleImagesInput {
  position: relative;
  overflow: hidden;
  border: $input-border-1;
  border-radius: 0.8rem;
  /* height: 20rem; */
  width: 100%;
  transition: $normal-transition;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1.6rem;
  margin-bottom: 1rem;
  background-color: var(--input-background-color);

  &:hover {
    border: $input-border-2;
  }

  &-dragOver {
    border: $input-border-1-focus;
  }

  &-label {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--main-text-color);
    left: 1rem;
    top: 0.5rem;
    transition: $normal-transition;
    font-family: var(--main-font-primary) !important;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 15rem;
    margin: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    &--content {
      /* height: 7.5rem;
    width: 7.5rem; */
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 0.5rem;

      &-dragText-2 {
        color: var(--main-brand-color);
      }

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: var(--main-font-primary) !important;
        img {
          height: 100%;
          width: 100%;
          cursor: pointer;
          object-fit: contain;
        }

        span {
          font-family: var(--main-font-secondary) !important;
          font-size: 1.1rem;
          font-weight: 300 !important;
          color: #a2a5b9;
          max-width: 100%;
          width: 100vw;
          text-align: center;

          &:last-child {
            @media only screen and (max-width: 750px) {
              display: none;
            }
          }
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 4rem;
          width: 6rem;
          /* background-color: aqua; */
          transition: all 0.3s ease;
          &:hover {
            cursor: pointer;
          }

          svg {
            transition: all 0.3s ease;
            fill: rgb(197, 197, 197);
            height: 4rem;
            width: 4rem;

            &:hover {
              fill: var(--main-brand-color);
            }
          }
        }
      }

      input {
        display: none;
      }
    }
  }

  &__images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    padding: 0.5rem;
    padding-top: 0rem;
    &--image {
      height: 7rem;
      width: 7rem;
      background-color: var(--input-background-color);
      margin: 0.5rem;
      border-radius: $normal-border-radius;
      overflow: hidden;
      /* border: 2px solid transparent; */
      border: $input-border-small;
      box-shadow: $normal-box-shadow-plus;
      transition: $normal-transition;
      position: relative;

      &:hover {
        /* border: 2px solid #a2a5b9; */
        cursor: pointer;
      }

      &-remove {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0.3rem;
        top: 0.3rem;
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 50%;
        background-color: #d0d4ee;
        svg {
          height: 1rem;
          width: 1rem;
          color: var(--main-text-color);
          transition: $normal-transition;

          &:hover {
            color: $web-color-danger;
          }
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      video {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
